const route = [{lat:42.560766, lng:-71.201207},
{lat:42.560762, lng:-71.2012},
{lat:42.560618, lng:-71.201346},
{lat:42.562255, lng:-71.203497},
{lat:42.562431, lng:-71.203702},
{lat:42.562719, lng:-71.203969},
{lat:42.563193, lng:-71.204364},
{lat:42.563723, lng:-71.204902},
{lat:42.565183, lng:-71.207072},
{lat:42.565545, lng:-71.207751},
{lat:42.565736, lng:-71.207643},
{lat:42.565736, lng:-71.207643},
{lat:42.566176, lng:-71.20732},
{lat:42.566303, lng:-71.20731},
{lat:42.566417, lng:-71.207389},
{lat:42.566469, lng:-71.207481},
{lat:42.566925, lng:-71.208684},
{lat:42.567196, lng:-71.209345},
{lat:42.567196, lng:-71.209345},
{lat:42.567553, lng:-71.210213},
{lat:42.567998, lng:-71.211216},
{lat:42.567508, lng:-71.211578},
{lat:42.567443, lng:-71.211604},
{lat:42.567443, lng:-71.211604},
{lat:42.567265, lng:-71.211676},
{lat:42.567329, lng:-71.212045},
{lat:42.567438, lng:-71.212535},
{lat:42.567585, lng:-71.213055},
{lat:42.567585, lng:-71.213055},
{lat:42.56765, lng:-71.213286},
{lat:42.567055, lng:-71.213589},
{lat:42.56694, lng:-71.213619},
{lat:42.56694, lng:-71.213619},
{lat:42.565994, lng:-71.213578},
{lat:42.565838, lng:-71.213532},
{lat:42.565743, lng:-71.213466},
{lat:42.565641, lng:-71.213349},
{lat:42.565231, lng:-71.212574},
{lat:42.565098, lng:-71.21235},
{lat:42.564593, lng:-71.211647},
{lat:42.564385, lng:-71.211877},
{lat:42.564385, lng:-71.211877},
{lat:42.564288, lng:-71.211984},
{lat:42.564314, lng:-71.212071},
{lat:42.564286, lng:-71.212158},
{lat:42.564222, lng:-71.212193},
{lat:42.564158, lng:-71.212155},
{lat:42.563879, lng:-71.21249},
{lat:42.564047, lng:-71.212711},
{lat:42.564047, lng:-71.212711},
{lat:42.564176, lng:-71.212922},
{lat:42.56423, lng:-71.213136},
{lat:42.564227, lng:-71.213275},
{lat:42.564201, lng:-71.213385},
{lat:42.564169, lng:-71.213451},
{lat:42.56409, lng:-71.213551},
{lat:42.563692, lng:-71.213794},
{lat:42.563497, lng:-71.213955},
{lat:42.563362, lng:-71.214035},
{lat:42.563275, lng:-71.214047},
{lat:42.563122, lng:-71.214011},
{lat:42.563029, lng:-71.213953},
{lat:42.562902, lng:-71.213824},
{lat:42.56268, lng:-71.213521},
{lat:42.562106, lng:-71.212601},
{lat:42.562106, lng:-71.212601},
{lat:42.561875, lng:-71.212304},
{lat:42.56171, lng:-71.212139},
{lat:42.561165, lng:-71.211676},
{lat:42.561063, lng:-71.211535},
{lat:42.560926, lng:-71.211261},
{lat:42.560926, lng:-71.211261},
{lat:42.560877, lng:-71.211114},
{lat:42.560838, lng:-71.210868},
{lat:42.561014, lng:-71.210852},
{lat:42.561369, lng:-71.210884},
{lat:42.561545, lng:-71.210874},
{lat:42.561693, lng:-71.210845},
{lat:42.562089, lng:-71.210692},
{lat:42.562209, lng:-71.210667},
{lat:42.562307, lng:-71.210678},
{lat:42.562307, lng:-71.210678},
{lat:42.562462, lng:-71.210739},
{lat:42.562613, lng:-71.210885},
{lat:42.562866, lng:-71.211325},
{lat:42.56305, lng:-71.211583},
{lat:42.563734, lng:-71.212332},
{lat:42.563734, lng:-71.212332},
{lat:42.563879, lng:-71.21249},
{lat:42.564158, lng:-71.212155},
{lat:42.564133, lng:-71.212084},
{lat:42.56414, lng:-71.212021},
{lat:42.564179, lng:-71.211962},
{lat:42.564224, lng:-71.211946},
{lat:42.564288, lng:-71.211984},
{lat:42.564593, lng:-71.211647},
{lat:42.565393, lng:-71.210847},
{lat:42.565393, lng:-71.210847},
{lat:42.566493, lng:-71.209747},
{lat:42.566493, lng:-71.209747},
{lat:42.566566, lng:-71.209675},
{lat:42.565628, lng:-71.207908},
{lat:42.565628, lng:-71.207908},
{lat:42.565183, lng:-71.207072},
{lat:42.563911, lng:-71.205168},
{lat:42.564349, lng:-71.205308},
{lat:42.564932, lng:-71.205515},
{lat:42.564932, lng:-71.205515},
{lat:42.565203, lng:-71.20561},
{lat:42.56584, lng:-71.205937},
{lat:42.566045, lng:-71.206013},
{lat:42.566276, lng:-71.206068},
{lat:42.566621, lng:-71.206116},
{lat:42.566831, lng:-71.206153},
{lat:42.56707, lng:-71.206203},
{lat:42.567303, lng:-71.206279},
{lat:42.567403, lng:-71.206321},
{lat:42.567597, lng:-71.206451},
{lat:42.568821, lng:-71.207444},
{lat:42.569231, lng:-71.207887},
{lat:42.569609, lng:-71.207062},
{lat:42.569609, lng:-71.207062},
{lat:42.569823, lng:-71.206596},
{lat:42.570119, lng:-71.206853},
{lat:42.570119, lng:-71.206853},
{lat:42.57029, lng:-71.207},
{lat:42.570417, lng:-71.207055},
{lat:42.570539, lng:-71.207052},
{lat:42.570539, lng:-71.207052},
{lat:42.570558, lng:-71.207051},
{lat:42.570558, lng:-71.207051},
{lat:42.570417, lng:-71.207055},
{lat:42.57029, lng:-71.207},
{lat:42.569927, lng:-71.206687},
{lat:42.569927, lng:-71.206687},
{lat:42.569823, lng:-71.206596},
{lat:42.569231, lng:-71.207887},
{lat:42.569261, lng:-71.207922},
{lat:42.569261, lng:-71.207922},
{lat:42.569587, lng:-71.208293},
{lat:42.569965, lng:-71.208682},
{lat:42.570277, lng:-71.20894},
{lat:42.571056, lng:-71.209421},
{lat:42.571872, lng:-71.210134},
{lat:42.572456, lng:-71.210595},
{lat:42.572456, lng:-71.210595},
{lat:42.572507, lng:-71.210635},
{lat:42.572312, lng:-71.211081},
{lat:42.57223, lng:-71.21121},
{lat:42.572142, lng:-71.211272},
{lat:42.571962, lng:-71.211293},
{lat:42.5717, lng:-71.21123},
{lat:42.571542, lng:-71.211216},
{lat:42.571542, lng:-71.211216},
{lat:42.571391, lng:-71.211203},
{lat:42.571359, lng:-71.211994},
{lat:42.571311, lng:-71.212098},
{lat:42.571226, lng:-71.212155},
{lat:42.570927, lng:-71.212253},
{lat:42.570927, lng:-71.212253},
{lat:42.570388, lng:-71.212431},
{lat:42.570271, lng:-71.212445},
{lat:42.570148, lng:-71.212424},
{lat:42.570036, lng:-71.212369},
{lat:42.569897, lng:-71.212198},
{lat:42.569512, lng:-71.21145},
{lat:42.569512, lng:-71.21145},
{lat:42.569445, lng:-71.211287},
{lat:42.569445, lng:-71.21118},
{lat:42.569483, lng:-71.21109},
{lat:42.569777, lng:-71.21077},
{lat:42.568924, lng:-71.209319},
{lat:42.568924, lng:-71.209319},
{lat:42.568173, lng:-71.20804},
{lat:42.568144, lng:-71.20797},
{lat:42.568123, lng:-71.20782},
{lat:42.568151, lng:-71.207655},
{lat:42.568337, lng:-71.207152},
{lat:42.568337, lng:-71.207152},
{lat:42.568367, lng:-71.207072},
{lat:42.567597, lng:-71.206451},
{lat:42.567403, lng:-71.206321},
{lat:42.56707, lng:-71.206203},
{lat:42.566831, lng:-71.206153},
{lat:42.566621, lng:-71.206116},
{lat:42.566276, lng:-71.206068},
{lat:42.566001, lng:-71.205997},
{lat:42.566001, lng:-71.205997},
{lat:42.56584, lng:-71.205937},
{lat:42.565203, lng:-71.20561},
{lat:42.564349, lng:-71.205308},
{lat:42.564234, lng:-71.20533},
{lat:42.564132, lng:-71.2055},
{lat:42.563723, lng:-71.204902},
{lat:42.563193, lng:-71.204364},
{lat:42.562719, lng:-71.203969},
{lat:42.562431, lng:-71.203702},
{lat:42.562255, lng:-71.203497},
{lat:42.561612, lng:-71.202655},
{lat:42.56168, lng:-71.202573},
{lat:42.56185, lng:-71.202456},
{lat:42.56185, lng:-71.202456},
{lat:42.562465, lng:-71.202054},
{lat:42.563147, lng:-71.201569},
{lat:42.563442, lng:-71.201315},
{lat:42.563789, lng:-71.200892},
{lat:42.563904, lng:-71.200707},
{lat:42.56404, lng:-71.200435},
{lat:42.564257, lng:-71.199925},
{lat:42.56435, lng:-71.199705},
{lat:42.564608, lng:-71.199094},
{lat:42.56478, lng:-71.198853},
{lat:42.564483, lng:-71.198088},
{lat:42.564483, lng:-71.198088},
{lat:42.564364, lng:-71.197766},
{lat:42.564339, lng:-71.197647},
{lat:42.564323, lng:-71.19742},
{lat:42.564327, lng:-71.196836},
{lat:42.564293, lng:-71.196483},
{lat:42.564195, lng:-71.195833},
{lat:42.563972, lng:-71.194871},
{lat:42.563862, lng:-71.1945},
{lat:42.563653, lng:-71.193981},
{lat:42.563602, lng:-71.193814},
{lat:42.56351, lng:-71.192869},
{lat:42.563495, lng:-71.192633},
{lat:42.56349, lng:-71.192055},
{lat:42.56343, lng:-71.191792},
{lat:42.563305, lng:-71.191397},
{lat:42.563305, lng:-71.191397},
{lat:42.563255, lng:-71.191241},
{lat:42.563204, lng:-71.19097},
{lat:42.563115, lng:-71.189586},
{lat:42.563116, lng:-71.189358},
{lat:42.563176, lng:-71.188996},
{lat:42.563311, lng:-71.188558},
{lat:42.563412, lng:-71.188357},
{lat:42.563782, lng:-71.187796},
{lat:42.564146, lng:-71.187095},
{lat:42.564146, lng:-71.187095},
{lat:42.564354, lng:-71.186697},
{lat:42.564673, lng:-71.186216},
{lat:42.564889, lng:-71.185937},
{lat:42.564984, lng:-71.185781},
{lat:42.56537, lng:-71.184923},
{lat:42.565436, lng:-71.184753},
{lat:42.565475, lng:-71.184594},
{lat:42.565423, lng:-71.18457},
{lat:42.565423, lng:-71.18457},
{lat:42.5637, lng:-71.183751},
{lat:42.56319, lng:-71.183572},
{lat:42.561312, lng:-71.182976},
{lat:42.561337, lng:-71.182818},
{lat:42.561337, lng:-71.182818},
{lat:42.561583, lng:-71.181256},
{lat:42.561627, lng:-71.181044},
{lat:42.561691, lng:-71.180952},
{lat:42.562984, lng:-71.180459},
{lat:42.562984, lng:-71.180459},
{lat:42.56313, lng:-71.180403},
{lat:42.563365, lng:-71.18085},
{lat:42.563482, lng:-71.180993},
{lat:42.56384, lng:-71.181287},
{lat:42.564265, lng:-71.181684},
{lat:42.564265, lng:-71.181684},
{lat:42.564691, lng:-71.182082},
{lat:42.564833, lng:-71.182193},
{lat:42.5652, lng:-71.180937},
{lat:42.5652, lng:-71.180937},
{lat:42.565405, lng:-71.180237},
{lat:42.565452, lng:-71.180205},
{lat:42.565463, lng:-71.180061},
{lat:42.565552, lng:-71.179898},
{lat:42.565664, lng:-71.179816},
{lat:42.565664, lng:-71.179816},
{lat:42.56578, lng:-71.179789},
{lat:42.565854, lng:-71.179802},
{lat:42.565923, lng:-71.179841},
{lat:42.565983, lng:-71.179903},
{lat:42.566045, lng:-71.18003},
{lat:42.566064, lng:-71.180128},
{lat:42.566052, lng:-71.180293},
{lat:42.566052, lng:-71.180293},
{lat:42.565983, lng:-71.180455},
{lat:42.565923, lng:-71.180517},
{lat:42.565854, lng:-71.180556},
{lat:42.56578, lng:-71.180569},
{lat:42.56567, lng:-71.18054},
{lat:42.565509, lng:-71.180391},
{lat:42.565509, lng:-71.180391},
{lat:42.565471, lng:-71.18033},
{lat:42.565452, lng:-71.180205},
{lat:42.565405, lng:-71.180237},
{lat:42.564833, lng:-71.182193},
{lat:42.56469, lng:-71.182081},
{lat:42.56469, lng:-71.182081},
{lat:42.56384, lng:-71.181287},
{lat:42.563482, lng:-71.180993},
{lat:42.563365, lng:-71.18085},
{lat:42.56313, lng:-71.180403},
{lat:42.563321, lng:-71.180297},
{lat:42.563321, lng:-71.180297},
{lat:42.564622, lng:-71.17958},
{lat:42.564376, lng:-71.178761},
{lat:42.5658, lng:-71.177969},
{lat:42.5658, lng:-71.177969},
{lat:42.565999, lng:-71.177843},
{lat:42.566036, lng:-71.177766},
{lat:42.566021, lng:-71.17762},
{lat:42.565573, lng:-71.176206},
{lat:42.565322, lng:-71.175342},
{lat:42.565701, lng:-71.17513},
{lat:42.565701, lng:-71.17513},
{lat:42.567747, lng:-71.173998},
{lat:42.567813, lng:-71.174215},
{lat:42.567813, lng:-71.174215},
{lat:42.568658, lng:-71.177004},
{lat:42.568715, lng:-71.177099},
{lat:42.568795, lng:-71.177085},
{lat:42.569292, lng:-71.176777},
{lat:42.56935, lng:-71.176714},
{lat:42.569408, lng:-71.17661},
{lat:42.569408, lng:-71.17661},
{lat:42.569606, lng:-71.176245},
{lat:42.569659, lng:-71.176107},
{lat:42.569709, lng:-71.175351},
{lat:42.569766, lng:-71.175198},
{lat:42.569869, lng:-71.175099},
{lat:42.570339, lng:-71.174957},
{lat:42.570367, lng:-71.175128},
{lat:42.570367, lng:-71.175128},
{lat:42.570582, lng:-71.176456},
{lat:42.570577, lng:-71.176588},
{lat:42.570522, lng:-71.176747},
{lat:42.570163, lng:-71.177154},
{lat:42.570163, lng:-71.177154},
{lat:42.570129, lng:-71.177193},
{lat:42.57006, lng:-71.177227},
{lat:42.569949, lng:-71.177226},
{lat:42.569853, lng:-71.177175},
{lat:42.569795, lng:-71.177111},
{lat:42.569617, lng:-71.176659},
{lat:42.569559, lng:-71.176572},
{lat:42.569475, lng:-71.176492},
{lat:42.56935, lng:-71.176714},
{lat:42.569292, lng:-71.176777},
{lat:42.568966, lng:-71.176979},
{lat:42.568966, lng:-71.176979},
{lat:42.568795, lng:-71.177085},
{lat:42.568715, lng:-71.177099},
{lat:42.568658, lng:-71.177004},
{lat:42.567747, lng:-71.173998},
{lat:42.567293, lng:-71.174249},
{lat:42.567293, lng:-71.174249},
{lat:42.564639, lng:-71.175722},
{lat:42.564639, lng:-71.175722},
{lat:42.561622, lng:-71.177383},
{lat:42.561622, lng:-71.177383},
{lat:42.561502, lng:-71.177449},
{lat:42.561377, lng:-71.176902},
{lat:42.561331, lng:-71.176505},
{lat:42.561317, lng:-71.175546},
{lat:42.56128, lng:-71.175132},
{lat:42.561211, lng:-71.174708},
{lat:42.56103, lng:-71.173848},
{lat:42.560919, lng:-71.17348},
{lat:42.560762, lng:-71.173124},
{lat:42.560762, lng:-71.173124},
{lat:42.560518, lng:-71.172633},
{lat:42.560161, lng:-71.17201},
{lat:42.560103, lng:-71.171883},
{lat:42.559926, lng:-71.171403},
{lat:42.559537, lng:-71.170239},
{lat:42.559467, lng:-71.169982},
{lat:42.559423, lng:-71.169659},
{lat:42.55944, lng:-71.16938},
{lat:42.559499, lng:-71.169052},
{lat:42.559134, lng:-71.168989},
{lat:42.559134, lng:-71.168989},
{lat:42.558832, lng:-71.168879},
{lat:42.55868, lng:-71.168884},
{lat:42.558298, lng:-71.169051},
{lat:42.558143, lng:-71.169161},
{lat:42.557917, lng:-71.169402},
{lat:42.557781, lng:-71.169492},
{lat:42.557536, lng:-71.168774},
{lat:42.557485, lng:-71.168697},
{lat:42.55672, lng:-71.168043}]

export default route