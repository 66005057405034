const route = [{lat:42.585658, lng:-71.14657},
{lat:42.58567, lng:-71.146651},
{lat:42.585948, lng:-71.146595},
{lat:42.586277, lng:-71.146587},
{lat:42.586659, lng:-71.146657},
{lat:42.587136, lng:-71.146789},
{lat:42.587584, lng:-71.146975},
{lat:42.587697, lng:-71.147002},
{lat:42.588357, lng:-71.147031},
{lat:42.588554, lng:-71.146989},
{lat:42.588931, lng:-71.146854},
{lat:42.589124, lng:-71.14682},
{lat:42.589327, lng:-71.146823},
{lat:42.589695, lng:-71.146886},
{lat:42.589695, lng:-71.146886},
{lat:42.590272, lng:-71.146985},
{lat:42.592241, lng:-71.147672},
{lat:42.592627, lng:-71.147728},
{lat:42.59282, lng:-71.147721},
{lat:42.593139, lng:-71.147663},
{lat:42.593312, lng:-71.147604},
{lat:42.593312, lng:-71.147604},
{lat:42.593549, lng:-71.147515},
{lat:42.594503, lng:-71.147078},
{lat:42.596336, lng:-71.146256},
{lat:42.596336, lng:-71.146256},
{lat:42.597039, lng:-71.145944},
{lat:42.597303, lng:-71.145884},
{lat:42.59746, lng:-71.145893},
{lat:42.597651, lng:-71.145937},
{lat:42.598131, lng:-71.146099},
{lat:42.598311, lng:-71.146114},
{lat:42.599057, lng:-71.145932},
{lat:42.599457, lng:-71.145937},
{lat:42.599457, lng:-71.145937},
{lat:42.599703, lng:-71.145957},
{lat:42.600097, lng:-71.146061},
{lat:42.600373, lng:-71.146104},
{lat:42.600517, lng:-71.146085},
{lat:42.600554, lng:-71.146262},
{lat:42.600724, lng:-71.146625},
{lat:42.600882, lng:-71.146902},
{lat:42.60104, lng:-71.147086},
{lat:42.60116, lng:-71.147183},
{lat:42.601354, lng:-71.147286},
{lat:42.601588, lng:-71.147352},
{lat:42.601588, lng:-71.147352},
{lat:42.601948, lng:-71.147455},
{lat:42.602081, lng:-71.147558},
{lat:42.602155, lng:-71.147653},
{lat:42.602215, lng:-71.147765},
{lat:42.602258, lng:-71.147891},
{lat:42.602288, lng:-71.148094},
{lat:42.602171, lng:-71.149419},
{lat:42.602171, lng:-71.149419},
{lat:42.602143, lng:-71.149716},
{lat:42.602159, lng:-71.149948},
{lat:42.602325, lng:-71.150826},
{lat:42.602402, lng:-71.150973},
{lat:42.602471, lng:-71.151048},
{lat:42.602551, lng:-71.151101},
{lat:42.603174, lng:-71.151308},
{lat:42.603293, lng:-71.15133},
{lat:42.604337, lng:-71.151376},
{lat:42.604337, lng:-71.151376},
{lat:42.604746, lng:-71.151394},
{lat:42.604867, lng:-71.151364},
{lat:42.604977, lng:-71.151289},
{lat:42.605069, lng:-71.151177},
{lat:42.60512, lng:-71.151073},
{lat:42.605163, lng:-71.15093},
{lat:42.605204, lng:-71.150703},
{lat:42.605214, lng:-71.150492},
{lat:42.605199, lng:-71.150357},
{lat:42.605162, lng:-71.15021},
{lat:42.605103, lng:-71.150081},
{lat:42.604998, lng:-71.149955},
{lat:42.604853, lng:-71.149878},
{lat:42.604617, lng:-71.149842},
{lat:42.604617, lng:-71.149842},
{lat:42.60337, lng:-71.149656},
{lat:42.603165, lng:-71.149553},
{lat:42.602721, lng:-71.149134},
{lat:42.602612, lng:-71.149052},
{lat:42.602425, lng:-71.148991},
{lat:42.602425, lng:-71.148991},
{lat:42.602213, lng:-71.148954},
{lat:42.602288, lng:-71.148094},
{lat:42.602258, lng:-71.147891},
{lat:42.602215, lng:-71.147765},
{lat:42.602155, lng:-71.147653},
{lat:42.602081, lng:-71.147558},
{lat:42.601948, lng:-71.147455},
{lat:42.601354, lng:-71.147286},
{lat:42.60116, lng:-71.147183},
{lat:42.60104, lng:-71.147086},
{lat:42.600882, lng:-71.146902},
{lat:42.600724, lng:-71.146625},
{lat:42.600547, lng:-71.146229},
{lat:42.600547, lng:-71.146229},
{lat:42.600517, lng:-71.146085},
{lat:42.600373, lng:-71.146104},
{lat:42.600097, lng:-71.146061},
{lat:42.600108, lng:-71.146283},
{lat:42.600348, lng:-71.146771},
{lat:42.600348, lng:-71.146771},
{lat:42.600497, lng:-71.147075},
{lat:42.600552, lng:-71.147251},
{lat:42.600668, lng:-71.147468},
{lat:42.600734, lng:-71.147669},
{lat:42.601197, lng:-71.151457},
{lat:42.601179, lng:-71.151631},
{lat:42.601146, lng:-71.151715},
{lat:42.601146, lng:-71.151715},
{lat:42.600878, lng:-71.152067},
{lat:42.600206, lng:-71.151229},
{lat:42.600206, lng:-71.151229},
{lat:42.600167, lng:-71.151164},
{lat:42.600114, lng:-71.150979},
{lat:42.600032, lng:-71.150032},
{lat:42.599963, lng:-71.14982},
{lat:42.599852, lng:-71.149629},
{lat:42.599522, lng:-71.149136},
{lat:42.599449, lng:-71.148987},
{lat:42.599351, lng:-71.148475},
{lat:42.599351, lng:-71.148475},
{lat:42.599325, lng:-71.148342},
{lat:42.599441, lng:-71.148293},
{lat:42.600439, lng:-71.147357},
{lat:42.600439, lng:-71.147357},
{lat:42.600552, lng:-71.147251},
{lat:42.600431, lng:-71.147134},
{lat:42.599919, lng:-71.146159},
{lat:42.599879, lng:-71.146004},
{lat:42.599723, lng:-71.145962},
{lat:42.599723, lng:-71.145962},
{lat:42.599316, lng:-71.145925},
{lat:42.599057, lng:-71.145932},
{lat:42.598311, lng:-71.146114},
{lat:42.598131, lng:-71.146099},
{lat:42.597651, lng:-71.145937},
{lat:42.59746, lng:-71.145893},
{lat:42.597303, lng:-71.145884},
{lat:42.597039, lng:-71.145944},
{lat:42.594503, lng:-71.147078},
{lat:42.593549, lng:-71.147515},
{lat:42.593139, lng:-71.147663},
{lat:42.59282, lng:-71.147721},
{lat:42.592627, lng:-71.147728},
{lat:42.592241, lng:-71.147672},
{lat:42.591744, lng:-71.147494},
{lat:42.591628, lng:-71.147709},
{lat:42.591628, lng:-71.147709},
{lat:42.591102, lng:-71.148687},
{lat:42.589074, lng:-71.152501},
{lat:42.587873, lng:-71.1548},
{lat:42.587445, lng:-71.155512},
{lat:42.587062, lng:-71.155962},
{lat:42.58658, lng:-71.156403},
{lat:42.58658, lng:-71.156403},
{lat:42.586142, lng:-71.156692},
{lat:42.585515, lng:-71.15698},
{lat:42.583423, lng:-71.158021},
{lat:42.583064, lng:-71.158177},
{lat:42.582714, lng:-71.158289},
{lat:42.582419, lng:-71.158342},
{lat:42.582063, lng:-71.15834},
{lat:42.581589, lng:-71.158227},
{lat:42.581166, lng:-71.158031},
{lat:42.580847, lng:-71.15781},
{lat:42.580177, lng:-71.157231},
{lat:42.579187, lng:-71.156461},
{lat:42.579187, lng:-71.156461},
{lat:42.578803, lng:-71.156167},
{lat:42.578592, lng:-71.156028},
{lat:42.578498, lng:-71.156},
{lat:42.578397, lng:-71.156},
{lat:42.578281, lng:-71.156049},
{lat:42.578057, lng:-71.156285},
{lat:42.577494, lng:-71.156994},
{lat:42.577494, lng:-71.156994},
{lat:42.576945, lng:-71.157677},
{lat:42.576669, lng:-71.157951},
{lat:42.576431, lng:-71.158245},
{lat:42.576333, lng:-71.158646},
{lat:42.576302, lng:-71.158872},
{lat:42.57631, lng:-71.159585},
{lat:42.576465, lng:-71.159601},
{lat:42.576465, lng:-71.159601},
{lat:42.576725, lng:-71.159649},
{lat:42.578041, lng:-71.160261},
{lat:42.578041, lng:-71.160261},
{lat:42.578282, lng:-71.160374},
{lat:42.578543, lng:-71.160526},
{lat:42.578735, lng:-71.160667},
{lat:42.579568, lng:-71.161388},
{lat:42.580447, lng:-71.162062},
{lat:42.58082, lng:-71.162315},
{lat:42.580919, lng:-71.162362},
{lat:42.581104, lng:-71.162383},
{lat:42.581339, lng:-71.162325},
{lat:42.581451, lng:-71.162329},
{lat:42.581567, lng:-71.162372},
{lat:42.581567, lng:-71.162372},
{lat:42.581692, lng:-71.162467},
{lat:42.581742, lng:-71.162525},
{lat:42.581821, lng:-71.162673},
{lat:42.582084, lng:-71.163358},
{lat:42.582244, lng:-71.163976},
{lat:42.582261, lng:-71.164104},
{lat:42.582222, lng:-71.164208},
{lat:42.582161, lng:-71.16425},
{lat:42.581659, lng:-71.164451},
{lat:42.581659, lng:-71.164451},
{lat:42.580545, lng:-71.164897},
{lat:42.580437, lng:-71.164924},
{lat:42.579614, lng:-71.164961},
{lat:42.579614, lng:-71.164961},
{lat:42.57939, lng:-71.164954},
{lat:42.579348, lng:-71.164922},
{lat:42.579306, lng:-71.164846},
{lat:42.579298, lng:-71.164783},
{lat:42.579241, lng:-71.161299},
{lat:42.579241, lng:-71.161299},
{lat:42.579284, lng:-71.161146},
{lat:42.578735, lng:-71.160667},
{lat:42.578543, lng:-71.160526},
{lat:42.578282, lng:-71.160374},
{lat:42.578255, lng:-71.160538},
{lat:42.578264, lng:-71.161032},
{lat:42.578264, lng:-71.161032},
{lat:42.578355, lng:-71.165818},
{lat:42.578345, lng:-71.165904},
{lat:42.578312, lng:-71.165996},
{lat:42.578282, lng:-71.166026},
{lat:42.578212, lng:-71.165998},
{lat:42.578212, lng:-71.165998},
{lat:42.577453, lng:-71.165342},
{lat:42.577381, lng:-71.16522},
{lat:42.577364, lng:-71.165083},
{lat:42.577336, lng:-71.163152},
{lat:42.577336, lng:-71.163152},
{lat:42.577293, lng:-71.160089},
{lat:42.577293, lng:-71.160089},
{lat:42.57732, lng:-71.159923},
{lat:42.576725, lng:-71.159649},
{lat:42.576602, lng:-71.159615},
{lat:42.57631, lng:-71.159585},
{lat:42.57633, lng:-71.160658},
{lat:42.57633, lng:-71.160658},
{lat:42.57642, lng:-71.165105},
{lat:42.576387, lng:-71.165487},
{lat:42.576333, lng:-71.165718},
{lat:42.576086, lng:-71.166401},
{lat:42.575875, lng:-71.166908},
{lat:42.575608, lng:-71.167362},
{lat:42.575115, lng:-71.168068},
{lat:42.575006, lng:-71.168264},
{lat:42.574902, lng:-71.168512},
{lat:42.574843, lng:-71.168723},
{lat:42.574819, lng:-71.169099},
{lat:42.574843, lng:-71.16945},
{lat:42.574893, lng:-71.169619},
{lat:42.57505, lng:-71.16994},
{lat:42.574887, lng:-71.17008},
{lat:42.574887, lng:-71.17008},
{lat:42.574622, lng:-71.170273},
{lat:42.574489, lng:-71.170334},
{lat:42.573196, lng:-71.17058},
{lat:42.572734, lng:-71.170636},
{lat:42.572638, lng:-71.170656},
{lat:42.572572, lng:-71.170692},
{lat:42.572535, lng:-71.170747},
{lat:42.572518, lng:-71.17081},
{lat:42.572587, lng:-71.171605},
{lat:42.572587, lng:-71.171605},
{lat:42.572632, lng:-71.172047},
{lat:42.572664, lng:-71.17213},
{lat:42.572711, lng:-71.172167},
{lat:42.572765, lng:-71.172173},
{lat:42.574621, lng:-71.171856},
{lat:42.574621, lng:-71.171856},
{lat:42.575093, lng:-71.171773},
{lat:42.575249, lng:-71.171724},
{lat:42.57532, lng:-71.171683},
{lat:42.575452, lng:-71.171557},
{lat:42.575817, lng:-71.171138},
{lat:42.575971, lng:-71.171492},
{lat:42.575971, lng:-71.171492},
{lat:42.576027, lng:-71.171681},
{lat:42.576072, lng:-71.171912},
{lat:42.576131, lng:-71.172743},
{lat:42.576227, lng:-71.173631},
{lat:42.576226, lng:-71.173803},
{lat:42.576182, lng:-71.174338},
{lat:42.576192, lng:-71.17462},
{lat:42.576246, lng:-71.17494},
{lat:42.57639, lng:-71.175498},
{lat:42.576658, lng:-71.17642},
{lat:42.576797, lng:-71.17701},
{lat:42.57684, lng:-71.177355},
{lat:42.57684, lng:-71.178172},
{lat:42.57684, lng:-71.178172},
{lat:42.57684, lng:-71.1783},
{lat:42.577761, lng:-71.178152},
{lat:42.577761, lng:-71.178152},
{lat:42.578814, lng:-71.177983},
{lat:42.578916, lng:-71.179242},
{lat:42.578944, lng:-71.179359},
{lat:42.578973, lng:-71.179401},
{lat:42.579004, lng:-71.179421},
{lat:42.57909, lng:-71.179428},
{lat:42.579606, lng:-71.179366},
{lat:42.579777, lng:-71.179316},
{lat:42.580002, lng:-71.179201},
{lat:42.580002, lng:-71.179201},
{lat:42.580106, lng:-71.179172},
{lat:42.580166, lng:-71.179225},
{lat:42.580257, lng:-71.17946},
{lat:42.580312, lng:-71.179531},
{lat:42.580371, lng:-71.179529},
{lat:42.580661, lng:-71.179386},
{lat:42.581186, lng:-71.179082},
{lat:42.582237, lng:-71.178119},
{lat:42.582495, lng:-71.177906},
{lat:42.583032, lng:-71.177576},
{lat:42.583538, lng:-71.177115},
{lat:42.583641, lng:-71.17705},
{lat:42.583753, lng:-71.177026},
{lat:42.584428, lng:-71.177014},
{lat:42.584561, lng:-71.177043},
{lat:42.584561, lng:-71.177043},
{lat:42.584712, lng:-71.17712},
{lat:42.584837, lng:-71.17723},
{lat:42.584954, lng:-71.177385},
{lat:42.585192, lng:-71.177897},
{lat:42.585239, lng:-71.178034},
{lat:42.585239, lng:-71.178034},
{lat:42.585263, lng:-71.178172},
{lat:42.585256, lng:-71.178307},
{lat:42.585238, lng:-71.178379},
{lat:42.585172, lng:-71.178508},
{lat:42.585103, lng:-71.178587},
{lat:42.585029, lng:-71.178635},
{lat:42.584928, lng:-71.178653},
{lat:42.584813, lng:-71.178634},
{lat:42.584813, lng:-71.178634},
{lat:42.584739, lng:-71.178589},
{lat:42.584658, lng:-71.178492},
{lat:42.584599, lng:-71.178367},
{lat:42.584348, lng:-71.178622},
{lat:42.58402, lng:-71.178853},
{lat:42.58402, lng:-71.178853},
{lat:42.583877, lng:-71.179},
{lat:42.583797, lng:-71.179129},
{lat:42.583545, lng:-71.17979},
{lat:42.583461, lng:-71.179919},
{lat:42.583407, lng:-71.179963},
{lat:42.583276, lng:-71.180019},
{lat:42.583126, lng:-71.179996},
{lat:42.583016, lng:-71.179914},
{lat:42.582917, lng:-71.179748},
{lat:42.582753, lng:-71.17936},
{lat:42.582753, lng:-71.17936},
{lat:42.582237, lng:-71.178119},
{lat:42.581186, lng:-71.179082},
{lat:42.580661, lng:-71.179386},
{lat:42.580371, lng:-71.179529},
{lat:42.580312, lng:-71.179531},
{lat:42.580249, lng:-71.179441},
{lat:42.580249, lng:-71.179441},
{lat:42.580166, lng:-71.179225},
{lat:42.580106, lng:-71.179172},
{lat:42.580028, lng:-71.179187},
{lat:42.579777, lng:-71.179316},
{lat:42.579606, lng:-71.179366},
{lat:42.57909, lng:-71.179428},
{lat:42.579004, lng:-71.179421},
{lat:42.578973, lng:-71.179401},
{lat:42.578944, lng:-71.179359},
{lat:42.578916, lng:-71.179242},
{lat:42.578814, lng:-71.177983},
{lat:42.57705, lng:-71.178266},
{lat:42.57705, lng:-71.178266},
{lat:42.57684, lng:-71.1783},
{lat:42.576842, lng:-71.179356},
{lat:42.576879, lng:-71.179669},
{lat:42.576999, lng:-71.180247},
{lat:42.57717, lng:-71.180934},
{lat:42.57701, lng:-71.181006},
{lat:42.576909, lng:-71.181082},
{lat:42.576845, lng:-71.181193},
{lat:42.576845, lng:-71.181193},
{lat:42.576749, lng:-71.181415},
{lat:42.576701, lng:-71.181466},
{lat:42.576566, lng:-71.181553},
{lat:42.576965, lng:-71.182711},
{lat:42.576971, lng:-71.182774},
{lat:42.576939, lng:-71.182813},
{lat:42.576719, lng:-71.182953},
{lat:42.576719, lng:-71.182953},
{lat:42.576493, lng:-71.183097},
{lat:42.576101, lng:-71.181953},
{lat:42.576101, lng:-71.181953},
{lat:42.575852, lng:-71.181219},
{lat:42.575871, lng:-71.181147},
{lat:42.576276, lng:-71.180883},
{lat:42.57632, lng:-71.180881},
{lat:42.57635, lng:-71.180927},
{lat:42.576394, lng:-71.181054},
{lat:42.576394, lng:-71.181054},
{lat:42.576566, lng:-71.181553},
{lat:42.576701, lng:-71.181466},
{lat:42.576749, lng:-71.181415},
{lat:42.57687, lng:-71.181136},
{lat:42.576909, lng:-71.181082},
{lat:42.57701, lng:-71.181006},
{lat:42.57717, lng:-71.180934},
{lat:42.577004, lng:-71.180266},
{lat:42.577004, lng:-71.180266},
{lat:42.576879, lng:-71.179669},
{lat:42.576842, lng:-71.179356},
{lat:42.57684, lng:-71.177355},
{lat:42.576797, lng:-71.17701},
{lat:42.576658, lng:-71.17642},
{lat:42.57639, lng:-71.175498},
{lat:42.57631, lng:-71.175541},
{lat:42.57631, lng:-71.175541},
{lat:42.574882, lng:-71.176307},
{lat:42.574771, lng:-71.176379},
{lat:42.574545, lng:-71.176606},
{lat:42.57439, lng:-71.176724},
{lat:42.573961, lng:-71.176971},
{lat:42.573342, lng:-71.177367},
{lat:42.57232, lng:-71.178106},
{lat:42.569615, lng:-71.179814},
{lat:42.569615, lng:-71.179814},
{lat:42.569442, lng:-71.179926},
{lat:42.569979, lng:-71.181446},
{lat:42.569979, lng:-71.181446},
{lat:42.570009, lng:-71.181531},
{lat:42.568109, lng:-71.182737},
{lat:42.568109, lng:-71.182737},
{lat:42.566351, lng:-71.183855},
{lat:42.566351, lng:-71.183855},
{lat:42.56629, lng:-71.183893},
{lat:42.566265, lng:-71.183633},
{lat:42.566265, lng:-71.183633},
{lat:42.56629, lng:-71.183893},
{lat:42.566381, lng:-71.184442},
{lat:42.566528, lng:-71.184992},
{lat:42.566538, lng:-71.185088},
{lat:42.56653, lng:-71.185162},
{lat:42.566262, lng:-71.184988},
{lat:42.566262, lng:-71.184988},
{lat:42.566119, lng:-71.184895},
{lat:42.5637, lng:-71.183751},
{lat:42.562693, lng:-71.183414},
{lat:42.562615, lng:-71.183851},
{lat:42.562615, lng:-71.183851},
{lat:42.562436, lng:-71.184847},
{lat:42.562391, lng:-71.184922},
{lat:42.562326, lng:-71.184936},
{lat:42.560718, lng:-71.184425},
{lat:42.560718, lng:-71.184425},
{lat:42.559551, lng:-71.184064},
{lat:42.558876, lng:-71.183828},
{lat:42.558894, lng:-71.183726},
{lat:42.558894, lng:-71.183726},
{lat:42.559127, lng:-71.182381}]

export default route