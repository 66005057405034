const route = [{lat:42.568688, lng:-71.148351},
{lat:42.568686, lng:-71.148359},
{lat:42.568811, lng:-71.148405},
{lat:42.568952, lng:-71.148408},
{lat:42.569416, lng:-71.148286},
{lat:42.569532, lng:-71.14828},
{lat:42.569666, lng:-71.148302},
{lat:42.569814, lng:-71.148361},
{lat:42.570325, lng:-71.148679},
{lat:42.570522, lng:-71.148782},
{lat:42.570986, lng:-71.148987},
{lat:42.571956, lng:-71.149295},
{lat:42.572155, lng:-71.149389},
{lat:42.572528, lng:-71.149638},
{lat:42.57266, lng:-71.149769},
{lat:42.572782, lng:-71.14995},
{lat:42.572907, lng:-71.150247},
{lat:42.572999, lng:-71.150559},
{lat:42.57305, lng:-71.150886},
{lat:42.573051, lng:-71.151106},
{lat:42.573027, lng:-71.151345},
{lat:42.572688, lng:-71.153516},
{lat:42.57258, lng:-71.154118},
{lat:42.572421, lng:-71.154062},
{lat:42.572421, lng:-71.154062},
{lat:42.57009, lng:-71.153234},
{lat:42.570116, lng:-71.1531},
{lat:42.570116, lng:-71.1531},
{lat:42.570321, lng:-71.152037},
{lat:42.568977, lng:-71.151521},
{lat:42.567876, lng:-71.151012},
{lat:42.566781, lng:-71.150634},
{lat:42.564135, lng:-71.149791},
{lat:42.564135, lng:-71.149791},
{lat:42.562882, lng:-71.149374},
{lat:42.562579, lng:-71.149295},
{lat:42.562538, lng:-71.1493},
{lat:42.562495, lng:-71.14937},
{lat:42.562399, lng:-71.149922},
{lat:42.562399, lng:-71.149922},
{lat:42.562248, lng:-71.150791},
{lat:42.562267, lng:-71.150862},
{lat:42.562304, lng:-71.15089},
{lat:42.563937, lng:-71.151408},
{lat:42.563937, lng:-71.151408},
{lat:42.56418, lng:-71.151485},
{lat:42.564087, lng:-71.152803},
{lat:42.564085, lng:-71.153159},
{lat:42.564104, lng:-71.153333},
{lat:42.564165, lng:-71.153552},
{lat:42.564197, lng:-71.153623},
{lat:42.564288, lng:-71.15375},
{lat:42.564458, lng:-71.153872},
{lat:42.566896, lng:-71.155073},
{lat:42.566962, lng:-71.155124},
{lat:42.567062, lng:-71.155242},
{lat:42.567397, lng:-71.155799},
{lat:42.567452, lng:-71.155859},
{lat:42.567659, lng:-71.156},
{lat:42.570372, lng:-71.157234},
{lat:42.570412, lng:-71.157287},
{lat:42.570412, lng:-71.157287},
{lat:42.570515, lng:-71.157425},
{lat:42.570616, lng:-71.157323},
{lat:42.570946, lng:-71.157096},
{lat:42.573389, lng:-71.156002},
{lat:42.573751, lng:-71.155807},
{lat:42.57435, lng:-71.155404},
{lat:42.574854, lng:-71.15499},
{lat:42.57536, lng:-71.154468},
{lat:42.575862, lng:-71.153866},
{lat:42.576381, lng:-71.153155},
{lat:42.576381, lng:-71.153155},
{lat:42.576866, lng:-71.152475},
{lat:42.577389, lng:-71.151761},
{lat:42.578243, lng:-71.150539},
{lat:42.57858, lng:-71.150021},
{lat:42.578833, lng:-71.149513},
{lat:42.578997, lng:-71.149092},
{lat:42.579149, lng:-71.148585},
{lat:42.579223, lng:-71.148264},
{lat:42.579223, lng:-71.148264},
{lat:42.579283, lng:-71.147926},
{lat:42.578799, lng:-71.147862},
{lat:42.578595, lng:-71.147866},
{lat:42.578595, lng:-71.147866},
{lat:42.578363, lng:-71.147888},
{lat:42.578087, lng:-71.147975},
{lat:42.577291, lng:-71.148412},
{lat:42.576969, lng:-71.148557},
{lat:42.576714, lng:-71.148642},
{lat:42.576694, lng:-71.148035},
{lat:42.576694, lng:-71.148035},
{lat:42.576658, lng:-71.147003},
{lat:42.576666, lng:-71.146903},
{lat:42.576415, lng:-71.146911},
{lat:42.576415, lng:-71.146911},
{lat:42.575932, lng:-71.146926},
{lat:42.575616, lng:-71.146961},
{lat:42.57548, lng:-71.147025},
{lat:42.575008, lng:-71.147413},
{lat:42.574836, lng:-71.147489},
{lat:42.574715, lng:-71.1475},
{lat:42.574536, lng:-71.147459},
{lat:42.57397, lng:-71.14713},
{lat:42.573729, lng:-71.147041},
{lat:42.573542, lng:-71.147016},
{lat:42.572614, lng:-71.146984},
{lat:42.572614, lng:-71.146984},
{lat:42.572382, lng:-71.146945},
{lat:42.572457, lng:-71.146635},
{lat:42.572534, lng:-71.146399},
{lat:42.572684, lng:-71.14613},
{lat:42.572824, lng:-71.14597},
{lat:42.573011, lng:-71.145795},
{lat:42.573011, lng:-71.145795},
{lat:42.573198, lng:-71.14562},
{lat:42.573318, lng:-71.14555},
{lat:42.573487, lng:-71.14549},
{lat:42.573656, lng:-71.145476},
{lat:42.574699, lng:-71.145524},
{lat:42.574897, lng:-71.145611},
{lat:42.575231, lng:-71.145898},
{lat:42.575364, lng:-71.145963},
{lat:42.575458, lng:-71.145985},
{lat:42.57645, lng:-71.145927},
{lat:42.57645, lng:-71.145927},
{lat:42.576635, lng:-71.145916},
{lat:42.576615, lng:-71.145262},
{lat:42.576615, lng:-71.145262},
{lat:42.576579, lng:-71.144124},
{lat:42.57643, lng:-71.144106},
{lat:42.576316, lng:-71.144051},
{lat:42.576226, lng:-71.143935},
{lat:42.576156, lng:-71.143755},
{lat:42.576113, lng:-71.143323},
{lat:42.576087, lng:-71.142478},
{lat:42.575906, lng:-71.142488},
{lat:42.575906, lng:-71.142488},
{lat:42.575053, lng:-71.142534},
{lat:42.575035, lng:-71.142001},
{lat:42.574987, lng:-71.141664},
{lat:42.574966, lng:-71.141409},
{lat:42.574945, lng:-71.140672},
{lat:42.574945, lng:-71.140672},
{lat:42.57486, lng:-71.137735},
{lat:42.574878, lng:-71.137607},
{lat:42.574918, lng:-71.137524},
{lat:42.575386, lng:-71.136852},
{lat:42.575386, lng:-71.136852},
{lat:42.575425, lng:-71.136795},
{lat:42.575519, lng:-71.136748},
{lat:42.576643, lng:-71.136686},
{lat:42.576715, lng:-71.13672},
{lat:42.576755, lng:-71.136816},
{lat:42.576758, lng:-71.136929},
{lat:42.576758, lng:-71.136929},
{lat:42.576827, lng:-71.13943},
{lat:42.577055, lng:-71.139422},
{lat:42.577055, lng:-71.139422},
{lat:42.577793, lng:-71.139393},
{lat:42.57783, lng:-71.140319},
{lat:42.57783, lng:-71.140319},
{lat:42.577885, lng:-71.142382},
{lat:42.578159, lng:-71.142403},
{lat:42.578468, lng:-71.142521},
{lat:42.578468, lng:-71.142521},
{lat:42.578622, lng:-71.142579},
{lat:42.578427, lng:-71.143388},
{lat:42.578394, lng:-71.1436},
{lat:42.578183, lng:-71.146202},
{lat:42.578183, lng:-71.146202},
{lat:42.578059, lng:-71.147771},
{lat:42.578087, lng:-71.147975},
{lat:42.578363, lng:-71.147888},
{lat:42.578659, lng:-71.14786},
{lat:42.579445, lng:-71.14793},
{lat:42.579445, lng:-71.14793},
{lat:42.579949, lng:-71.147942},
{lat:42.582669, lng:-71.148246},
{lat:42.582934, lng:-71.148225},
{lat:42.583128, lng:-71.148181},
{lat:42.583625, lng:-71.147908},
{lat:42.583908, lng:-71.147708},
{lat:42.584473, lng:-71.147181},
{lat:42.584625, lng:-71.14707},
{lat:42.585021, lng:-71.146881},
{lat:42.585021, lng:-71.146881},
{lat:42.585528, lng:-71.146693},
{lat:42.585534, lng:-71.146613}]

export default route