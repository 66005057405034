const route = [{ lat: 42.550377, lng: -71.170431 },
 { lat: 42.55038, lng: -71.170437 },
 { lat: 42.546579, lng: -71.173452 },
 { lat: 42.546579, lng: -71.173452 },
 { lat: 42.546439, lng: -71.17359 },
 { lat: 42.546342, lng: -71.17373 },
 { lat: 42.546055, lng: -71.17404 },
 { lat: 42.54565, lng: -71.174342 },
 { lat: 42.545125, lng: -71.174638 },
 { lat: 42.544893, lng: -71.174808 },
 { lat: 42.544726, lng: -71.174975 },
 { lat: 42.5442, lng: -71.175642 },
 { lat: 42.543736, lng: -71.176445 },
 { lat: 42.543332, lng: -71.176983 },
 { lat: 42.543184, lng: -71.177261 },
 { lat: 42.542968, lng: -71.177857 },
 { lat: 42.542869, lng: -71.178078 },
 { lat: 42.542682, lng: -71.178388 },
 { lat: 42.542459, lng: -71.178649 },
 { lat: 42.542293, lng: -71.178878 },
 { lat: 42.542002, lng: -71.179351 },
 { lat: 42.542002, lng: -71.179351 },
 { lat: 42.541849, lng: -71.179599 },
 { lat: 42.542004, lng: -71.179731 },
 { lat: 42.542077, lng: -71.179772 },
 { lat: 42.542213, lng: -71.179809 },
 { lat: 42.543121, lng: -71.179835 },
 { lat: 42.543121, lng: -71.179835 },
 { lat: 42.543414, lng: -71.179843 },
 { lat: 42.543509, lng: -71.179865 },
 { lat: 42.543604, lng: -71.179929 },
 { lat: 42.543604, lng: -71.179929 },
 { lat: 42.543704, lng: -71.180031 },
 { lat: 42.543726, lng: -71.180089 },
 { lat: 42.543729, lng: -71.180159 },
 { lat: 42.543697, lng: -71.18023 },
 { lat: 42.54365, lng: -71.180268 },
 { lat: 42.543579, lng: -71.180243 },
 { lat: 42.543579, lng: -71.180243 },
 { lat: 42.543489, lng: -71.180211 },
 { lat: 42.5433, lng: -71.18009 },
 { lat: 42.543247, lng: -71.179999 },
 { lat: 42.543247, lng: -71.179999 },
 { lat: 42.543226, lng: -71.179963 },
 { lat: 42.54322, lng: -71.179837 },
 { lat: 42.542213, lng: -71.179809 },
 { lat: 42.542077, lng: -71.179772 },
 { lat: 42.541945, lng: -71.179681 },
 { lat: 42.541945, lng: -71.179681 },
 { lat: 42.541849, lng: -71.179599 },
 { lat: 42.541748, lng: -71.179526 },
 { lat: 42.541155, lng: -71.179925 },
 { lat: 42.540738, lng: -71.180182 },
 { lat: 42.540738, lng: -71.180182 },
 { lat: 42.539145, lng: -71.181163 },
 { lat: 42.538988, lng: -71.181275 },
 { lat: 42.538737, lng: -71.181512 },
 { lat: 42.538053, lng: -71.182488 },
 { lat: 42.537968, lng: -71.182585 },
 { lat: 42.537968, lng: -71.182585 },
 { lat: 42.537815, lng: -71.182727 },
 { lat: 42.537632, lng: -71.182802 },
 { lat: 42.537259, lng: -71.182783 },
 { lat: 42.537174, lng: -71.182807 },
 { lat: 42.536838, lng: -71.182984 },
 { lat: 42.536483, lng: -71.183203 },
 { lat: 42.536223, lng: -71.18339 },
 { lat: 42.53607, lng: -71.183523 },
 { lat: 42.535975, lng: -71.183688 },
 { lat: 42.535899, lng: -71.183891 },
 { lat: 42.535611, lng: -71.185049 },
 { lat: 42.535607, lng: -71.185165 },
 { lat: 42.535773, lng: -71.185544 },
 { lat: 42.535817, lng: -71.185746 },
 { lat: 42.535793, lng: -71.185943 },
 { lat: 42.535727, lng: -71.186105 },
 { lat: 42.535587, lng: -71.186239 },
 { lat: 42.535312, lng: -71.186339 },
 { lat: 42.535104, lng: -71.18647 },
 { lat: 42.534511, lng: -71.187053 },
 { lat: 42.534421, lng: -71.187124 },
 { lat: 42.534346, lng: -71.187151 },
 { lat: 42.534224, lng: -71.187148 },
 { lat: 42.534103, lng: -71.18708 },
 { lat: 42.533943, lng: -71.186889 },
 { lat: 42.533835, lng: -71.186722 },
 { lat: 42.533704, lng: -71.18645 },
 { lat: 42.533634, lng: -71.186135 },
 { lat: 42.533579, lng: -71.185406 },
 { lat: 42.533544, lng: -71.185151 },
 { lat: 42.533419, lng: -71.184561 },
 { lat: 42.5332, lng: -71.183747 },
 { lat: 42.53319, lng: -71.183575 },
 { lat: 42.533242, lng: -71.183382 },
 { lat: 42.533757, lng: -71.182304 },
 { lat: 42.533757, lng: -71.182304 },
 { lat: 42.533845, lng: -71.182123 },
 { lat: 42.533954, lng: -71.181771 },
 { lat: 42.533706, lng: -71.181665 },
 { lat: 42.533561, lng: -71.181654 },
 { lat: 42.533416, lng: -71.18167 },
 { lat: 42.533304, lng: -71.18171 },
 { lat: 42.533199, lng: -71.181779 },
 { lat: 42.532952, lng: -71.182034 },
 { lat: 42.532831, lng: -71.182096 },
 { lat: 42.532696, lng: -71.182118 },
 { lat: 42.53256, lng: -71.182102 },
 { lat: 42.532434, lng: -71.182052 },
 { lat: 42.532434, lng: -71.182052 },
 { lat: 42.532309, lng: -71.181962 },
 { lat: 42.532181, lng: -71.181804 },
 { lat: 42.532054, lng: -71.18152 },
 { lat: 42.532041, lng: -71.181419 },
 { lat: 42.532048, lng: -71.181321 },
 { lat: 42.531902, lng: -71.181229 },
 { lat: 42.531727, lng: -71.180888 },
 { lat: 42.531645, lng: -71.180797 },
 { lat: 42.531387, lng: -71.180676 },
 { lat: 42.531315, lng: -71.18068 },
 { lat: 42.531247, lng: -71.180708 },
 { lat: 42.531187, lng: -71.180761 },
 { lat: 42.530166, lng: -71.181935 },
 { lat: 42.530166, lng: -71.181935 },
 { lat: 42.530022, lng: -71.182102 },
 { lat: 42.529967, lng: -71.182201 },
 { lat: 42.529919, lng: -71.182363 },
 { lat: 42.529904, lng: -71.182548 },
 { lat: 42.529922, lng: -71.182933 },
 { lat: 42.529832, lng: -71.183269 },
 { lat: 42.529645, lng: -71.183218 },
 { lat: 42.52946, lng: -71.18323 },
 { lat: 42.529296, lng: -71.183348 },
 { lat: 42.528626, lng: -71.183955 },
 { lat: 42.528626, lng: -71.183955 },
 { lat: 42.528586, lng: -71.183992 },
 { lat: 42.528502, lng: -71.184158 },
 { lat: 42.528464, lng: -71.184283 },
 { lat: 42.528479, lng: -71.184466 },
 { lat: 42.528513, lng: -71.18462 },
 { lat: 42.528607, lng: -71.184786 },
 { lat: 42.528816, lng: -71.18506 },
 { lat: 42.528905, lng: -71.185137 },
 { lat: 42.528995, lng: -71.185148 },
 { lat: 42.529123, lng: -71.185112 },
 { lat: 42.529182, lng: -71.185085 },
 { lat: 42.529443, lng: -71.184773 },
 { lat: 42.529443, lng: -71.184773 },
 { lat: 42.529666, lng: -71.184506 },
 { lat: 42.529843, lng: -71.184349 },
 { lat: 42.530217, lng: -71.184075 },
 { lat: 42.530292, lng: -71.183961 },
 { lat: 42.530315, lng: -71.183891 },
 { lat: 42.53033, lng: -71.18374 },
 { lat: 42.530302, lng: -71.183593 },
 { lat: 42.530272, lng: -71.183527 },
 { lat: 42.530188, lng: -71.183426 },
 { lat: 42.530013, lng: -71.183346 },
 { lat: 42.530013, lng: -71.183346 },
 { lat: 42.529832, lng: -71.183269 },
 { lat: 42.529922, lng: -71.182933 },
 { lat: 42.529904, lng: -71.182548 },
 { lat: 42.529919, lng: -71.182363 },
 { lat: 42.529967, lng: -71.182201 },
 { lat: 42.530022, lng: -71.182102 },
 { lat: 42.531187, lng: -71.180761 },
 { lat: 42.531247, lng: -71.180708 },
 { lat: 42.531315, lng: -71.18068 },
 { lat: 42.531387, lng: -71.180676 },
 { lat: 42.531645, lng: -71.180797 },
 { lat: 42.531727, lng: -71.180888 },
 { lat: 42.531902, lng: -71.181229 },
 { lat: 42.532048, lng: -71.181321 },
 { lat: 42.532041, lng: -71.181419 },
 { lat: 42.532054, lng: -71.18152 },
 { lat: 42.532181, lng: -71.181804 },
 { lat: 42.532309, lng: -71.181962 },
 { lat: 42.532429, lng: -71.18205 },
 { lat: 42.53256, lng: -71.182102 },
 { lat: 42.532696, lng: -71.182118 },
 { lat: 42.532831, lng: -71.182096 },
 { lat: 42.532952, lng: -71.182034 },
 { lat: 42.533199, lng: -71.181779 },
 { lat: 42.533304, lng: -71.18171 },
 { lat: 42.533416, lng: -71.18167 },
 { lat: 42.533561, lng: -71.181654 },
 { lat: 42.533706, lng: -71.181665 },
 { lat: 42.533954, lng: -71.181771 },
 { lat: 42.533988, lng: -71.181637 },
 { lat: 42.533988, lng: -71.181637 },
 { lat: 42.53407, lng: -71.181317 },
 { lat: 42.534329, lng: -71.179988 },
 { lat: 42.534336, lng: -71.179733 },
 { lat: 42.534326, lng: -71.179606 },
 { lat: 42.534279, lng: -71.179358 },
 { lat: 42.534071, lng: -71.178723 },
 { lat: 42.533971, lng: -71.178577 },
 { lat: 42.53386, lng: -71.17848 },
 { lat: 42.533672, lng: -71.178396 },
 { lat: 42.53354, lng: -71.178385 },
 { lat: 42.533411, lng: -71.178413 },
 { lat: 42.533062, lng: -71.178562 },
 { lat: 42.532971, lng: -71.178572 },
 { lat: 42.532841, lng: -71.178517 },
 { lat: 42.53278, lng: -71.178444 },
 { lat: 42.532732, lng: -71.178329 },
 { lat: 42.532709, lng: -71.178189 },
 { lat: 42.532686, lng: -71.176946 },
 { lat: 42.532665, lng: -71.176691 },
 { lat: 42.532599, lng: -71.176342 },
 { lat: 42.532599, lng: -71.176342 },
 { lat: 42.532555, lng: -71.176146 },
 { lat: 42.532681, lng: -71.176084 },
 { lat: 42.532796, lng: -71.175963 },
 { lat: 42.532958, lng: -71.175674 },
 { lat: 42.533016, lng: -71.175522 },
 { lat: 42.533047, lng: -71.175379 },
 { lat: 42.533051, lng: -71.175031 },
 { lat: 42.532954, lng: -71.174445 },
 { lat: 42.532966, lng: -71.17435 },
 { lat: 42.533021, lng: -71.174168 },
 { lat: 42.533176, lng: -71.173841 },
 { lat: 42.533277, lng: -71.173729 },
 { lat: 42.533355, lng: -71.17369 },
 { lat: 42.533443, lng: -71.173692 },
 { lat: 42.533521, lng: -71.173729 },
 { lat: 42.533602, lng: -71.173795 },
 { lat: 42.533757, lng: -71.173511 },
 { lat: 42.533757, lng: -71.173511 },
 { lat: 42.535501, lng: -71.170317 },
 { lat: 42.535707, lng: -71.170023 },
 { lat: 42.535932, lng: -71.169609 },
 { lat: 42.536707, lng: -71.170032 },
 { lat: 42.536828, lng: -71.170167 },
 { lat: 42.536933, lng: -71.170442 },
 { lat: 42.536933, lng: -71.170442 },
 { lat: 42.537266, lng: -71.171871 },
 { lat: 42.537436, lng: -71.17246 },
 { lat: 42.537513, lng: -71.172584 },
 { lat: 42.537591, lng: -71.172649 },
 { lat: 42.537768, lng: -71.172705 },
 { lat: 42.539009, lng: -71.172805 },
 { lat: 42.539119, lng: -71.172847 },
 { lat: 42.539218, lng: -71.172926 },
 { lat: 42.539297, lng: -71.173037 },
 { lat: 42.539469, lng: -71.173489 },
 { lat: 42.539525, lng: -71.173693 },
 { lat: 42.539525, lng: -71.173693 },
 { lat: 42.539552, lng: -71.173912 },
 { lat: 42.539542, lng: -71.174254 },
 { lat: 42.538695, lng: -71.174169 },
 { lat: 42.538592, lng: -71.174185 },
 { lat: 42.53847, lng: -71.174266 },
 { lat: 42.538417, lng: -71.174352 },
 { lat: 42.538382, lng: -71.174515 },
 { lat: 42.538413, lng: -71.174886 },
 { lat: 42.538387, lng: -71.175049 },
 { lat: 42.538273, lng: -71.175238 },
 { lat: 42.537684, lng: -71.176071 },
 { lat: 42.537684, lng: -71.176071 },
 { lat: 42.53764, lng: -71.176133 },
 { lat: 42.537513, lng: -71.176065 },
 { lat: 42.537031, lng: -71.175899 },
 { lat: 42.536719, lng: -71.175732 },
 { lat: 42.536719, lng: -71.175732 },
 { lat: 42.536537, lng: -71.175625 },
 { lat: 42.535862, lng: -71.175123 },
 { lat: 42.535642, lng: -71.174978 },
 { lat: 42.534104, lng: -71.174149 },
 { lat: 42.534104, lng: -71.174149 },
 { lat: 42.533737, lng: -71.173931 },
 { lat: 42.533521, lng: -71.173729 },
 { lat: 42.533443, lng: -71.173692 },
 { lat: 42.533355, lng: -71.17369 },
 { lat: 42.533277, lng: -71.173729 },
 { lat: 42.533176, lng: -71.173841 },
 { lat: 42.533021, lng: -71.174168 },
 { lat: 42.532966, lng: -71.17435 },
 { lat: 42.532954, lng: -71.174445 },
 { lat: 42.533051, lng: -71.175031 },
 { lat: 42.533047, lng: -71.175379 },
 { lat: 42.533016, lng: -71.175522 },
 { lat: 42.532958, lng: -71.175674 },
 { lat: 42.532796, lng: -71.175963 },
 { lat: 42.532681, lng: -71.176084 },
 { lat: 42.532555, lng: -71.176146 },
 { lat: 42.532229, lng: -71.176152 },
 { lat: 42.531807, lng: -71.176128 },
 { lat: 42.531583, lng: -71.176095 },
 { lat: 42.531397, lng: -71.176037 },
 { lat: 42.531249, lng: -71.175923 },
 { lat: 42.531074, lng: -71.175727 },
 { lat: 42.530874, lng: -71.175187 },
 { lat: 42.530876, lng: -71.175186 },
 { lat: 42.530876, lng: -71.175186 },
 { lat: 42.530874, lng: -71.175187 },
 { lat: 42.530655, lng: -71.17452 },
 { lat: 42.530594, lng: -71.174424 },
 { lat: 42.53045, lng: -71.174279 },
 { lat: 42.530308, lng: -71.1742 },
 { lat: 42.530211, lng: -71.174172 },
 { lat: 42.529915, lng: -71.174168 },
 { lat: 42.529857, lng: -71.174143 },
 { lat: 42.529714, lng: -71.17399 },
 { lat: 42.529382, lng: -71.173476 },
 { lat: 42.529137, lng: -71.173204 },
 { lat: 42.528942, lng: -71.173065 },
 { lat: 42.528048, lng: -71.172554 },
 { lat: 42.527874, lng: -71.172483 },
 { lat: 42.527719, lng: -71.172443 },
 { lat: 42.527352, lng: -71.172399 },
 { lat: 42.527352, lng: -71.172399 },
 { lat: 42.526368, lng: -71.172282 },
 { lat: 42.526191, lng: -71.172188 },
 { lat: 42.525704, lng: -71.171677 },
 { lat: 42.525163, lng: -71.171053 },
 { lat: 42.525081, lng: -71.170917 },
 { lat: 42.524906, lng: -71.170384 },
 { lat: 42.524841, lng: -71.170269 },
 { lat: 42.52472, lng: -71.17013 },
 { lat: 42.524615, lng: -71.170047 },
 { lat: 42.524518, lng: -71.170016 },
 { lat: 42.524322, lng: -71.170034 },
 { lat: 42.523782, lng: -71.170292 },
 { lat: 42.523637, lng: -71.170321 },
 { lat: 42.523526, lng: -71.170317 },
 { lat: 42.523211, lng: -71.170255 },
 { lat: 42.523211, lng: -71.170255 },
 { lat: 42.522948, lng: -71.170203 },
 { lat: 42.522699, lng: -71.1701 },
 { lat: 42.522559, lng: -71.170005 },
 { lat: 42.522339, lng: -71.16982 },
 { lat: 42.522076, lng: -71.16953 },
 { lat: 42.521845, lng: -71.169198 },
 { lat: 42.521614, lng: -71.168974 },
 { lat: 42.52144, lng: -71.168864 },
 { lat: 42.521239, lng: -71.168786 },
 { lat: 42.520918, lng: -71.16877 },
 { lat: 42.520494, lng: -71.1688 },
 { lat: 42.519889, lng: -71.16888 },
 { lat: 42.519624, lng: -71.168868 },
 { lat: 42.518104, lng: -71.168312 },
 { lat: 42.517911, lng: -71.168279 },
 { lat: 42.517752, lng: -71.168294 },
 { lat: 42.517556, lng: -71.168364 },
 { lat: 42.517342, lng: -71.168506 },
 { lat: 42.517204, lng: -71.168622 },
 { lat: 42.516988, lng: -71.168861 },
 { lat: 42.51654, lng: -71.169441 },
 { lat: 42.516289, lng: -71.169681 },
 { lat: 42.516033, lng: -71.169861 },
 { lat: 42.516033, lng: -71.169861 },
 { lat: 42.515897, lng: -71.169939 },
 { lat: 42.515744, lng: -71.169986 },
 { lat: 42.515564, lng: -71.169971 },
 { lat: 42.515435, lng: -71.169895 },
 { lat: 42.51537, lng: -71.169832 },
 { lat: 42.515157, lng: -71.169516 },
 { lat: 42.514799, lng: -71.168937 },
 { lat: 42.514624, lng: -71.168723 },
 { lat: 42.514408, lng: -71.168555 },
 { lat: 42.514221, lng: -71.168463 },
 { lat: 42.514099, lng: -71.16844 },
 { lat: 42.513905, lng: -71.16845 },
 { lat: 42.513797, lng: -71.167745 },
 { lat: 42.513735, lng: -71.167239 },
 { lat: 42.513721, lng: -71.166984 },
 { lat: 42.513721, lng: -71.166984 },
 { lat: 42.513689, lng: -71.166419 },
 { lat: 42.513695, lng: -71.166029 },
 { lat: 42.513814, lng: -71.165165 },
 { lat: 42.51389, lng: -71.164472 },
 { lat: 42.513927, lng: -71.164275 },
 { lat: 42.513964, lng: -71.164153 },
 { lat: 42.514036, lng: -71.163996 },
 { lat: 42.514132, lng: -71.163856 },
 { lat: 42.514688, lng: -71.163224 },
 { lat: 42.514784, lng: -71.163088 },
 { lat: 42.514841, lng: -71.16295 },
 { lat: 42.514858, lng: -71.162734 },
 { lat: 42.514841, lng: -71.162428 },
 { lat: 42.514751, lng: -71.161563 },
 { lat: 42.514714, lng: -71.161333 },
 { lat: 42.514659, lng: -71.161165 },
 { lat: 42.514659, lng: -71.161165 },
 { lat: 42.514614, lng: -71.161028 },
 { lat: 42.514604, lng: -71.160912 },
 { lat: 42.515688, lng: -71.161265 },
 { lat: 42.515688, lng: -71.161265 },
 { lat: 42.515925, lng: -71.161342 },
 { lat: 42.516044, lng: -71.161362 },
 { lat: 42.516227, lng: -71.161347 },
 { lat: 42.516395, lng: -71.161285 },
 { lat: 42.516626, lng: -71.161127 },
 { lat: 42.517739, lng: -71.160018 },
 { lat: 42.519386, lng: -71.158367 },
 { lat: 42.519528, lng: -71.158269 },
 { lat: 42.519712, lng: -71.15821 },
 { lat: 42.520509, lng: -71.158097 },
 { lat: 42.5207, lng: -71.158096 },
 { lat: 42.520892, lng: -71.158136 },
 { lat: 42.521079, lng: -71.158232 },
 { lat: 42.5214, lng: -71.158565 },
 { lat: 42.522175, lng: -71.159525 },
 { lat: 42.522293, lng: -71.159633 },
 { lat: 42.522526, lng: -71.159801 },
 { lat: 42.52278, lng: -71.159924 },
 { lat: 42.52307, lng: -71.160002 },
 { lat: 42.523346, lng: -71.160019 },
 { lat: 42.523736, lng: -71.159968 },
 { lat: 42.524638, lng: -71.159611 },
 { lat: 42.52501, lng: -71.159509 },
 { lat: 42.525296, lng: -71.159478 },
 { lat: 42.526709, lng: -71.159527 },
 { lat: 42.526709, lng: -71.159527 },
 { lat: 42.52681, lng: -71.159531 },
 { lat: 42.526836, lng: -71.159444 },
 { lat: 42.527469, lng: -71.158068 },
 { lat: 42.527941, lng: -71.157129 },
 { lat: 42.528151, lng: -71.156806 },
 { lat: 42.528426, lng: -71.156466 },
 { lat: 42.528922, lng: -71.15591 },
 { lat: 42.529045, lng: -71.155705 },
 { lat: 42.529544, lng: -71.154652 },
 { lat: 42.529772, lng: -71.154293 },
 { lat: 42.529772, lng: -71.154293 },
 { lat: 42.531052, lng: -71.152644 },
 { lat: 42.531772, lng: -71.151592 },
 { lat: 42.531772, lng: -71.151592 },
 { lat: 42.531907, lng: -71.151397 },
 { lat: 42.532821, lng: -71.15029 },
 { lat: 42.532853, lng: -71.15023 },
 { lat: 42.532873, lng: -71.150116 },
 { lat: 42.533278, lng: -71.150217 },
 { lat: 42.533278, lng: -71.150217 },
 { lat: 42.533604, lng: -71.150299 },
 { lat: 42.534085, lng: -71.150387 },
 { lat: 42.534937, lng: -71.150459 },
 { lat: 42.535422, lng: -71.150477 },
 { lat: 42.535425, lng: -71.150433 },
 { lat: 42.535425, lng: -71.150433 },
 { lat: 42.535422, lng: -71.150477 },
 { lat: 42.535762, lng: -71.150449 },
 { lat: 42.536483, lng: -71.150318 },
 { lat: 42.537575, lng: -71.150042 },
 { lat: 42.537575, lng: -71.150042 },
 { lat: 42.538181, lng: -71.149888 },
 { lat: 42.538402, lng: -71.149809 },
 { lat: 42.538884, lng: -71.149547 },
 { lat: 42.538884, lng: -71.149547 },
 { lat: 42.538936, lng: -71.149519 },
 { lat: 42.538866, lng: -71.149238 },
 { lat: 42.538774, lng: -71.148667 },
 { lat: 42.538774, lng: -71.148667 },
 { lat: 42.538699, lng: -71.147689 },
 { lat: 42.539144, lng: -71.147635 },
 { lat: 42.539144, lng: -71.147635 },
 { lat: 42.539362, lng: -71.147609 },
 { lat: 42.53933, lng: -71.147099 },
 { lat: 42.53933, lng: -71.147099 },
 { lat: 42.539089, lng: -71.143074 },
 { lat: 42.539089, lng: -71.143074 },
 { lat: 42.538904, lng: -71.140182 },
 { lat: 42.538848, lng: -71.139736 },
 { lat: 42.538848, lng: -71.139736 },
 { lat: 42.538829, lng: -71.139581 },
 { lat: 42.539186, lng: -71.13954 },
 { lat: 42.53976, lng: -71.139555 },
 { lat: 42.540023, lng: -71.139589 },
 { lat: 42.540266, lng: -71.139659 },
 { lat: 42.540588, lng: -71.139822 },
 { lat: 42.540605, lng: -71.139762 },
 { lat: 42.540605, lng: -71.139762 },
 { lat: 42.540588, lng: -71.139822 },
 { lat: 42.540751, lng: -71.139957 },
 { lat: 42.540854, lng: -71.140112 },
 { lat: 42.541156, lng: -71.14072 },
 { lat: 42.541264, lng: -71.14085 },
 { lat: 42.541386, lng: -71.140926 },
 { lat: 42.541515, lng: -71.140947 },
 { lat: 42.541664, lng: -71.140901 },
 { lat: 42.54207, lng: -71.140583 },
 { lat: 42.54207, lng: -71.140583 },
 { lat: 42.542166, lng: -71.140534 },
 { lat: 42.542337, lng: -71.140527 },
 { lat: 42.542506, lng: -71.140604 },
 { lat: 42.542682, lng: -71.140789 },
 { lat: 42.542853, lng: -71.141096 },
 { lat: 42.54292, lng: -71.141265 },
 { lat: 42.543236, lng: -71.142282 },
 { lat: 42.543298, lng: -71.142433 },
 { lat: 42.543364, lng: -71.142548 },
 { lat: 42.543364, lng: -71.142548 },
 { lat: 42.543399, lng: -71.142608 },
 { lat: 42.543562, lng: -71.142733 },
 { lat: 42.544054, lng: -71.143004 },
 { lat: 42.544299, lng: -71.143109 },
 { lat: 42.544524, lng: -71.143175 },
 { lat: 42.544871, lng: -71.143209 },
 { lat: 42.545055, lng: -71.143266 },
 { lat: 42.545055, lng: -71.143266 },
 { lat: 42.545573, lng: -71.143437 },
 { lat: 42.545662, lng: -71.143493 },
 { lat: 42.545935, lng: -71.14373 },
 { lat: 42.546085, lng: -71.143802 },
 { lat: 42.546805, lng: -71.143934 },
 { lat: 42.546805, lng: -71.143934 },
 { lat: 42.547504, lng: -71.144072 },
 { lat: 42.547777, lng: -71.144164 },
 { lat: 42.548503, lng: -71.144509 },
 { lat: 42.548503, lng: -71.144509 },
 { lat: 42.549728, lng: -71.144983 },
 { lat: 42.550238, lng: -71.145085 },
 { lat: 42.550238, lng: -71.145085 },
 { lat: 42.550753, lng: -71.145238 },
 { lat: 42.550813, lng: -71.145277 },
 { lat: 42.550898, lng: -71.145393 },
 { lat: 42.550666, lng: -71.145581 },
 { lat: 42.550463, lng: -71.145849 },
 { lat: 42.549997, lng: -71.146701 },
 { lat: 42.549997, lng: -71.146701 },
 { lat: 42.54989, lng: -71.146896 },
 { lat: 42.549772, lng: -71.147163 },
 { lat: 42.549514, lng: -71.147853 },
 { lat: 42.549335, lng: -71.148244 },
 { lat: 42.548836, lng: -71.14901 },
 { lat: 42.548373, lng: -71.149552 },
 { lat: 42.548373, lng: -71.149552 },
 { lat: 42.548139, lng: -71.149805 },
 { lat: 42.547881, lng: -71.149976 },
 { lat: 42.547186, lng: -71.150249 },
 { lat: 42.546851, lng: -71.150343 },
 { lat: 42.546715, lng: -71.150362 },
 { lat: 42.546715, lng: -71.150362 },
 { lat: 42.546555, lng: -71.150385 },
 { lat: 42.546372, lng: -71.150374 },
 { lat: 42.545929, lng: -71.15026 },
 { lat: 42.545705, lng: -71.150249 },
 { lat: 42.54544, lng: -71.150301 },
 { lat: 42.54497, lng: -71.150437 },
 { lat: 42.54497, lng: -71.150437 },
 { lat: 42.544835, lng: -71.150476 },
 { lat: 42.544416, lng: -71.150512 },
 { lat: 42.544027, lng: -71.150492 },
 { lat: 42.54352, lng: -71.150415 },
 { lat: 42.543335, lng: -71.15036 },
 { lat: 42.543335, lng: -71.15036 },
 { lat: 42.542814, lng: -71.150166 },
 { lat: 42.542417, lng: -71.149975 },
 { lat: 42.541583, lng: -71.149528 },
 { lat: 42.541583, lng: -71.149528 },
 { lat: 42.540809, lng: -71.149097 },
 { lat: 42.540633, lng: -71.149014 },
 { lat: 42.540436, lng: -71.148962 },
 { lat: 42.540253, lng: -71.148952 },
 { lat: 42.540079, lng: -71.148985 },
 { lat: 42.539619, lng: -71.149188 },
 { lat: 42.539619, lng: -71.149188 },
 { lat: 42.539491, lng: -71.149244 },
 { lat: 42.539951, lng: -71.152228 },
 { lat: 42.539951, lng: -71.152228 },
 { lat: 42.540842, lng: -71.157676 },
 { lat: 42.541209, lng: -71.16024 },
 { lat: 42.541615, lng: -71.162926 },
 { lat: 42.541615, lng: -71.162926 },
 { lat: 42.541608, lng: -71.162881 },
 { lat: 42.541704, lng: -71.162848 },
 { lat: 42.542069, lng: -71.162546 },
 { lat: 42.542069, lng: -71.162546 },
 { lat: 42.543917, lng: -71.161 },
 { lat: 42.543917, lng: -71.161 },
 { lat: 42.543998, lng: -71.160903 },
 { lat: 42.544011, lng: -71.160849 },
 { lat: 42.544002, lng: -71.160739 },
 { lat: 42.543867, lng: -71.160227 },
 { lat: 42.543537, lng: -71.15879 },
 { lat: 42.543537, lng: -71.15879 },
 { lat: 42.543496, lng: -71.15861 },
 { lat: 42.54474, lng: -71.15767 },
 { lat: 42.544897, lng: -71.157581 },
 { lat: 42.544897, lng: -71.157581 },
 { lat: 42.545878, lng: -71.157231 },
 { lat: 42.545946, lng: -71.157226 },
 { lat: 42.546051, lng: -71.157259 },
 { lat: 42.546108, lng: -71.157306 },
 { lat: 42.546184, lng: -71.157416 },
 { lat: 42.546355, lng: -71.157834 },
 { lat: 42.546378, lng: -71.157967 },
 { lat: 42.546361, lng: -71.158095 },
 { lat: 42.546313, lng: -71.158194 },
 { lat: 42.546313, lng: -71.158194 },
 { lat: 42.546234, lng: -71.158274 },
 { lat: 42.544361, lng: -71.159706 },
 { lat: 42.544192, lng: -71.159886 },
 { lat: 42.543989, lng: -71.160149 },
 { lat: 42.543867, lng: -71.160227 },
 { lat: 42.544002, lng: -71.160739 },
 { lat: 42.544011, lng: -71.160849 },
 { lat: 42.543998, lng: -71.160903 },
 { lat: 42.543937, lng: -71.160984 },
 { lat: 42.542671, lng: -71.162042 },
 { lat: 42.542781, lng: -71.162263 },
 { lat: 42.542781, lng: -71.162263 },
 { lat: 42.543746, lng: -71.16423 },
 { lat: 42.544779, lng: -71.163413 },
 { lat: 42.544779, lng: -71.163413 },
 { lat: 42.546252, lng: -71.162244 },
 { lat: 42.546443, lng: -71.16259 },
 { lat: 42.546443, lng: -71.16259 },
 { lat: 42.546709, lng: -71.163073 },
 { lat: 42.546847, lng: -71.163214 },
 { lat: 42.546936, lng: -71.163271 },
 { lat: 42.547117, lng: -71.16332 },
 { lat: 42.547381, lng: -71.163269 },
 { lat: 42.547693, lng: -71.16315 },
 { lat: 42.547942, lng: -71.163078 },
 { lat: 42.54819, lng: -71.163038 },
 { lat: 42.548433, lng: -71.163031 },
 { lat: 42.54911, lng: -71.163192 },
 { lat: 42.552085, lng: -71.164057 },
 { lat: 42.55232, lng: -71.164151 },
 { lat: 42.553899, lng: -71.165069 },
 { lat: 42.55422, lng: -71.16532 },
 { lat: 42.554304, lng: -71.165422 },
 { lat: 42.554411, lng: -71.165632 },
 { lat: 42.554872, lng: -71.166855 },
 { lat: 42.555361, lng: -71.168215 },
 { lat: 42.555361, lng: -71.168215 },
 { lat: 42.555431, lng: -71.16841 },
 { lat: 42.555005, lng: -71.168845 },
 { lat: 42.554665, lng: -71.169104 },
 { lat: 42.553686, lng: -71.169747 },
 { lat: 42.553418, lng: -71.169961 },
 { lat: 42.553129, lng: -71.170295 },
 { lat: 42.552757, lng: -71.170794 },
 { lat: 42.552033, lng: -71.171673 },
 { lat: 42.551096, lng: -71.172738 },
 { lat: 42.551096, lng: -71.172738 },
 { lat: 42.550856, lng: -71.172963 },
 { lat: 42.550831, lng: -71.172844 },
 { lat: 42.550839, lng: -71.172548 },
 { lat: 42.550821, lng: -71.172339 },
 { lat: 42.550756, lng: -71.172098 },
 { lat: 42.550674, lng: -71.171927 },
 { lat: 42.550465, lng: -71.171628 },
 { lat: 42.550065, lng: -71.171122 }]

export default route