const route = [{ lat:42.550135, lng:-71.171241},
{ lat:42.550146, lng:-71.171225},
{ lat:42.549919, lng:-71.170934},
{ lat:42.549872, lng:-71.170835},
{ lat:42.550466, lng:-71.170369},
{ lat:42.550466, lng:-71.170369},
{ lat:42.551098, lng:-71.169862},
{ lat:42.551446, lng:-71.170536},
{ lat:42.551446, lng:-71.170536},
{ lat:42.552099, lng:-71.171819},
{ lat:42.552785, lng:-71.172994},
{ lat:42.552877, lng:-71.173209},
{ lat:42.552931, lng:-71.173463},
{ lat:42.55294, lng:-71.173657},
{ lat:42.55294, lng:-71.173657},
{ lat:42.552945, lng:-71.17378},
{ lat:42.552925, lng:-71.174013},
{ lat:42.552839, lng:-71.174352},
{ lat:42.552312, lng:-71.176169},
{ lat:42.55186, lng:-71.177872},
{ lat:42.55186, lng:-71.177872},
{ lat:42.551822, lng:-71.178019},
{ lat:42.55177, lng:-71.178145},
{ lat:42.552308, lng:-71.178599},
{ lat:42.553158, lng:-71.179176},
{ lat:42.553565, lng:-71.179429},
{ lat:42.553565, lng:-71.179429},
{ lat:42.55367, lng:-71.179495},
{ lat:42.553884, lng:-71.178818},
{ lat:42.554011, lng:-71.178533},
{ lat:42.554011, lng:-71.178533},
{ lat:42.554335, lng:-71.178129},
{ lat:42.554417, lng:-71.178207},
{ lat:42.554956, lng:-71.178535},
{ lat:42.554956, lng:-71.178535},
{ lat:42.555584, lng:-71.178914},
{ lat:42.555805, lng:-71.178919},
{ lat:42.555906, lng:-71.178946},
{ lat:42.55638, lng:-71.179228},
{ lat:42.55638, lng:-71.179228},
{ lat:42.556435, lng:-71.179261},
{ lat:42.556134, lng:-71.180172},
{ lat:42.556294, lng:-71.180268},
{ lat:42.556173, lng:-71.180632},
{ lat:42.556173, lng:-71.180632},
{ lat:42.556067, lng:-71.180951},
{ lat:42.556952, lng:-71.181488},
{ lat:42.557155, lng:-71.181597},
{ lat:42.557637, lng:-71.181811},
{ lat:42.557637, lng:-71.181811},
{ lat:42.557965, lng:-71.181923},
{ lat:42.558017, lng:-71.182027},
{ lat:42.558032, lng:-71.182095},
{ lat:42.558041, lng:-71.182237},
{ lat:42.558024, lng:-71.182434},
{ lat:42.557937, lng:-71.182829},
{ lat:42.557818, lng:-71.18316},
{ lat:42.557625, lng:-71.183829},
{ lat:42.557625, lng:-71.183829},
{ lat:42.557519, lng:-71.184154},
{ lat:42.557347, lng:-71.184598},
{ lat:42.55695, lng:-71.18549},
{ lat:42.556802, lng:-71.185795},
{ lat:42.55621, lng:-71.187084},
{ lat:42.556082, lng:-71.187392},
{ lat:42.555841, lng:-71.187243},
{ lat:42.555721, lng:-71.187106},
{ lat:42.555615, lng:-71.186915},
{ lat:42.555394, lng:-71.186407},
{ lat:42.555394, lng:-71.186407},
{ lat:42.555278, lng:-71.186137},
{ lat:42.555088, lng:-71.18576},
{ lat:42.554943, lng:-71.185525},
{ lat:42.553886, lng:-71.184224},
{ lat:42.553386, lng:-71.183517},
{ lat:42.553098, lng:-71.183045},
{ lat:42.552997, lng:-71.182838},
{ lat:42.55292, lng:-71.182613},
{ lat:42.552713, lng:-71.181875},
{ lat:42.552448, lng:-71.181077},
{ lat:42.552263, lng:-71.181112},
{ lat:42.552263, lng:-71.181112},
{ lat:42.551567, lng:-71.181241},
{ lat:42.550426, lng:-71.180979},
{ lat:42.550242, lng:-71.180921},
{ lat:42.55008, lng:-71.180841},
{ lat:42.54989, lng:-71.180723},
{ lat:42.549506, lng:-71.180434},
{ lat:42.549257, lng:-71.180202},
{ lat:42.549135, lng:-71.180011},
{ lat:42.548764, lng:-71.179219},
{ lat:42.548429, lng:-71.179493},
{ lat:42.548429, lng:-71.179493},
{ lat:42.547538, lng:-71.180247},
{ lat:42.547474, lng:-71.180282},
{ lat:42.547384, lng:-71.180262},
{ lat:42.547334, lng:-71.180195},
{ lat:42.546508, lng:-71.178416},
{ lat:42.546508, lng:-71.178416},
{ lat:42.546229, lng:-71.177816},
{ lat:42.546187, lng:-71.177763},
{ lat:42.546124, lng:-71.177734},
{ lat:42.546089, lng:-71.177764},
{ lat:42.545256, lng:-71.179219},
{ lat:42.545137, lng:-71.179364},
{ lat:42.544905, lng:-71.179352},
{ lat:42.544746, lng:-71.179284},
{ lat:42.544549, lng:-71.179161},
{ lat:42.544549, lng:-71.179161},
{ lat:42.543328, lng:-71.178398},
{ lat:42.543058, lng:-71.17821},
{ lat:42.543058, lng:-71.17821},
{ lat:42.542869, lng:-71.178078},
{ lat:42.542682, lng:-71.178388},
{ lat:42.542459, lng:-71.178649},
{ lat:42.542293, lng:-71.178878},
{ lat:42.541849, lng:-71.179599},
{ lat:42.541746, lng:-71.179803},
{ lat:42.541746, lng:-71.179803},
{ lat:42.541644, lng:-71.180038},
{ lat:42.54157, lng:-71.180394},
{ lat:42.541568, lng:-71.18061},
{ lat:42.541592, lng:-71.180809},
{ lat:42.541645, lng:-71.181008},
{ lat:42.541719, lng:-71.181179},
{ lat:42.54194, lng:-71.181535},
{ lat:42.542386, lng:-71.182108},
{ lat:42.542143, lng:-71.182444},
{ lat:42.542143, lng:-71.182444},
{ lat:42.541788, lng:-71.182935},
{ lat:42.541576, lng:-71.183496},
{ lat:42.54128, lng:-71.184118},
{ lat:42.54128, lng:-71.184118},
{ lat:42.540908, lng:-71.184897},
{ lat:42.540876, lng:-71.185046},
{ lat:42.540872, lng:-71.185157},
{ lat:42.540885, lng:-71.185259},
{ lat:42.540928, lng:-71.185384},
{ lat:42.540985, lng:-71.185485},
{ lat:42.54103, lng:-71.185539},
{ lat:42.541095, lng:-71.185579},
{ lat:42.541348, lng:-71.185632},
{ lat:42.541766, lng:-71.185627},
{ lat:42.542036, lng:-71.185561},
{ lat:42.542293, lng:-71.185443},
{ lat:42.542293, lng:-71.185443},
{ lat:42.542662, lng:-71.185227},
{ lat:42.542914, lng:-71.185129},
{ lat:42.543105, lng:-71.185088},
{ lat:42.543223, lng:-71.186124},
{ lat:42.543134, lng:-71.186213},
{ lat:42.542877, lng:-71.186706},
{ lat:42.542639, lng:-71.187103},
{ lat:42.542639, lng:-71.187103},
{ lat:42.542243, lng:-71.187746},
{ lat:42.542052, lng:-71.187985},
{ lat:42.54154, lng:-71.18855},
{ lat:42.54135, lng:-71.188223},
{ lat:42.54135, lng:-71.188223},
{ lat:42.540259, lng:-71.186324},
{ lat:42.540215, lng:-71.186273},
{ lat:42.540159, lng:-71.186249},
{ lat:42.540102, lng:-71.186258},
{ lat:42.540051, lng:-71.186296},
{ lat:42.539917, lng:-71.186464},
{ lat:42.539917, lng:-71.186464},
{ lat:42.539814, lng:-71.186652},
{ lat:42.539684, lng:-71.187075},
{ lat:42.539685, lng:-71.187185},
{ lat:42.539721, lng:-71.187285},
{ lat:42.54021, lng:-71.187747},
{ lat:42.540334, lng:-71.187905},
{ lat:42.540334, lng:-71.187905},
{ lat:42.540559, lng:-71.188297},
{ lat:42.54061, lng:-71.188338},
{ lat:42.540696, lng:-71.188324},
{ lat:42.541145, lng:-71.187872},
{ lat:42.541503, lng:-71.188487},
{ lat:42.541503, lng:-71.188487},
{ lat:42.54154, lng:-71.18855},
{ lat:42.541312, lng:-71.18883},
{ lat:42.541145, lng:-71.189105},
{ lat:42.54102, lng:-71.189354},
{ lat:42.540826, lng:-71.189866},
{ lat:42.540659, lng:-71.190418},
{ lat:42.540517, lng:-71.191215},
{ lat:42.540386, lng:-71.191591},
{ lat:42.539672, lng:-71.193352},
{ lat:42.539672, lng:-71.193352},
{ lat:42.539461, lng:-71.19388},
{ lat:42.539348, lng:-71.194244},
{ lat:42.539011, lng:-71.196215},
{ lat:42.538994, lng:-71.196603},
{ lat:42.539052, lng:-71.197107},
{ lat:42.539128, lng:-71.197361},
{ lat:42.539128, lng:-71.197361},
{ lat:42.539642, lng:-71.198886},
{ lat:42.539679, lng:-71.199077},
{ lat:42.539684, lng:-71.199218},
{ lat:42.53967, lng:-71.199438},
{ lat:42.53962, lng:-71.199661},
{ lat:42.539858, lng:-71.199798},
{ lat:42.539858, lng:-71.199798},
{ lat:42.540031, lng:-71.199863},
{ lat:42.540134, lng:-71.19986},
{ lat:42.540925, lng:-71.199636},
{ lat:42.541767, lng:-71.199292},
{ lat:42.542306, lng:-71.199161},
{ lat:42.542573, lng:-71.199142},
{ lat:42.543415, lng:-71.199193},
{ lat:42.543415, lng:-71.199193},
{ lat:42.543537, lng:-71.199208},
{ lat:42.543679, lng:-71.199189},
{ lat:42.54377, lng:-71.199151},
{ lat:42.544239, lng:-71.198772},
{ lat:42.544377, lng:-71.198638},
{ lat:42.544481, lng:-71.19857},
{ lat:42.544583, lng:-71.19854},
{ lat:42.544786, lng:-71.198551},
{ lat:42.544924, lng:-71.198612},
{ lat:42.545087, lng:-71.19879},
{ lat:42.545172, lng:-71.198958},
{ lat:42.545235, lng:-71.199162},
{ lat:42.545323, lng:-71.199611},
{ lat:42.545401, lng:-71.199863},
{ lat:42.545487, lng:-71.200033},
{ lat:42.545634, lng:-71.200223},
{ lat:42.545783, lng:-71.200359},
{ lat:42.545911, lng:-71.200417},
{ lat:42.54603, lng:-71.200439},
{ lat:42.546997, lng:-71.200314},
{ lat:42.546997, lng:-71.200314},
{ lat:42.54767, lng:-71.200232},
{ lat:42.548156, lng:-71.200206},
{ lat:42.548737, lng:-71.200231},
{ lat:42.549132, lng:-71.200272},
{ lat:42.549386, lng:-71.200328},
{ lat:42.549817, lng:-71.200495},
{ lat:42.549974, lng:-71.200526},
{ lat:42.549974, lng:-71.200526},
{ lat:42.550072, lng:-71.200546},
{ lat:42.550361, lng:-71.19923},
{ lat:42.550639, lng:-71.197749},
{ lat:42.550639, lng:-71.197749},
{ lat:42.550677, lng:-71.197547},
{ lat:42.55053, lng:-71.197413},
{ lat:42.550214, lng:-71.196971},
{ lat:42.550214, lng:-71.196971},
{ lat:42.549602, lng:-71.196113},
{ lat:42.549267, lng:-71.195609},
{ lat:42.54904, lng:-71.195188},
{ lat:42.548646, lng:-71.194522},
{ lat:42.548082, lng:-71.193771},
{ lat:42.547783, lng:-71.194098},
{ lat:42.547557, lng:-71.194284},
{ lat:42.547557, lng:-71.194284},
{ lat:42.547336, lng:-71.194464},
{ lat:42.546966, lng:-71.194809},
{ lat:42.546657, lng:-71.194969},
{ lat:42.545823, lng:-71.195217},
{ lat:42.545662, lng:-71.195287},
{ lat:42.544464, lng:-71.196001},
{ lat:42.544199, lng:-71.196223},
{ lat:42.543915, lng:-71.196568},
{ lat:42.54363, lng:-71.196802},
{ lat:42.543407, lng:-71.196916},
{ lat:42.543177, lng:-71.196987},
{ lat:42.543154, lng:-71.196759},
{ lat:42.543093, lng:-71.196608},
{ lat:42.543093, lng:-71.196608},
{ lat:42.542725, lng:-71.196198},
{ lat:42.542448, lng:-71.195803},
{ lat:42.542533, lng:-71.195737},
{ lat:42.542832, lng:-71.195606},
{ lat:42.543157, lng:-71.19557},
{ lat:42.543157, lng:-71.19557},
{ lat:42.543621, lng:-71.195565},
{ lat:42.543791, lng:-71.195483},
{ lat:42.544666, lng:-71.194725},
{ lat:42.544754, lng:-71.194695},
{ lat:42.545046, lng:-71.194692},
{ lat:42.545201, lng:-71.194631},
{ lat:42.54653, lng:-71.193825},
{ lat:42.546662, lng:-71.193673},
{ lat:42.546746, lng:-71.193424},
{ lat:42.546787, lng:-71.193341},
{ lat:42.546859, lng:-71.193248},
{ lat:42.547278, lng:-71.192935},
{ lat:42.547278, lng:-71.192935},
{ lat:42.547392, lng:-71.19285},
{ lat:42.548646, lng:-71.194522},
{ lat:42.54904, lng:-71.195188},
{ lat:42.549267, lng:-71.195609},
{ lat:42.549602, lng:-71.196113},
{ lat:42.55053, lng:-71.197413},
{ lat:42.550631, lng:-71.197504},
{ lat:42.550631, lng:-71.197504},
{ lat:42.550677, lng:-71.197547},
{ lat:42.551196, lng:-71.195888},
{ lat:42.551407, lng:-71.195257},
{ lat:42.551407, lng:-71.195257},
{ lat:42.551472, lng:-71.195086},
{ lat:42.550811, lng:-71.194106},
{ lat:42.55053, lng:-71.193641},
{ lat:42.550385, lng:-71.193277},
{ lat:42.550385, lng:-71.193277},
{ lat:42.550249, lng:-71.192855},
{ lat:42.551872, lng:-71.191955},
{ lat:42.551872, lng:-71.191955},
{ lat:42.552677, lng:-71.191509},
{ lat:42.552877, lng:-71.191442},
{ lat:42.553014, lng:-71.191442},
{ lat:42.55312, lng:-71.191467},
{ lat:42.553276, lng:-71.191546},
{ lat:42.553392, lng:-71.191647},
{ lat:42.553704, lng:-71.192047},
{ lat:42.553704, lng:-71.192047},
{ lat:42.553799, lng:-71.192169},
{ lat:42.5551, lng:-71.19078},
{ lat:42.555373, lng:-71.190564},
{ lat:42.555792, lng:-71.190287},
{ lat:42.555792, lng:-71.190287},
{ lat:42.556016, lng:-71.190139},
{ lat:42.556067, lng:-71.190401},
{ lat:42.556067, lng:-71.190401},
{ lat:42.556335, lng:-71.192058},
{ lat:42.556579, lng:-71.193742},
{ lat:42.556579, lng:-71.193742},
{ lat:42.556638, lng:-71.194144},
{ lat:42.556754, lng:-71.194764},
{ lat:42.556939, lng:-71.195365},
{ lat:42.55748, lng:-71.196887},
{ lat:42.55748, lng:-71.196887},
{ lat:42.557696, lng:-71.197417},
{ lat:42.558132, lng:-71.198255},
{ lat:42.558558, lng:-71.198882},
{ lat:42.558879, lng:-71.199313},
{ lat:42.559053, lng:-71.19907},
{ lat:42.559053, lng:-71.19907},
{ lat:42.559313, lng:-71.198686},
{ lat:42.559388, lng:-71.198533},
{ lat:42.559425, lng:-71.198383},
{ lat:42.55947, lng:-71.198011},
{ lat:42.559564, lng:-71.197859},
{ lat:42.560615, lng:-71.196647},
{ lat:42.560809, lng:-71.196497},
{ lat:42.561026, lng:-71.196419},
{ lat:42.56125, lng:-71.196418},
{ lat:42.561525, lng:-71.196516},
{ lat:42.561622, lng:-71.196592},
{ lat:42.561775, lng:-71.196774},
{ lat:42.561921, lng:-71.197036},
{ lat:42.562126, lng:-71.197556},
{ lat:42.562126, lng:-71.197556},
{ lat:42.562733, lng:-71.199113},
{ lat:42.562738, lng:-71.199191},
{ lat:42.562029, lng:-71.199911},
{ lat:42.562029, lng:-71.199911},
{ lat:42.560708, lng:-71.201254}]

export default route