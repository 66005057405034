const route = [{lat:42.549947, lng:-71.170769},
{lat:42.549949, lng:-71.170775},
{lat:42.556903, lng:-71.165239},
{lat:42.557348, lng:-71.164799},
{lat:42.557552, lng:-71.164548},
{lat:42.557985, lng:-71.164081},
{lat:42.558551, lng:-71.163625},
{lat:42.558551, lng:-71.163625},
{lat:42.55869, lng:-71.163515},
{lat:42.558763, lng:-71.163702},
{lat:42.559034, lng:-71.164796},
{lat:42.559034, lng:-71.164796},
{lat:42.559117, lng:-71.165129},
{lat:42.559427, lng:-71.164988},
{lat:42.559427, lng:-71.164988},
{lat:42.55966, lng:-71.164881},
{lat:42.559736, lng:-71.164904},
{lat:42.559787, lng:-71.165006},
{lat:42.560022, lng:-71.165838},
{lat:42.560145, lng:-71.166372},
{lat:42.560162, lng:-71.166738},
{lat:42.560162, lng:-71.166738},
{lat:42.560151, lng:-71.16761},
{lat:42.560123, lng:-71.167828},
{lat:42.560413, lng:-71.167894},
{lat:42.560413, lng:-71.167894},
{lat:42.560613, lng:-71.167939},
{lat:42.562991, lng:-71.167988},
{lat:42.563165, lng:-71.168009},
{lat:42.564644, lng:-71.168394},
{lat:42.564822, lng:-71.168473},
{lat:42.56505, lng:-71.168615},
{lat:42.565122, lng:-71.168693},
{lat:42.565122, lng:-71.168693},
{lat:42.565191, lng:-71.168768},
{lat:42.565349, lng:-71.169046},
{lat:42.565891, lng:-71.170157},
{lat:42.565956, lng:-71.170235},
{lat:42.566062, lng:-71.170292},
{lat:42.566271, lng:-71.170291},
{lat:42.568553, lng:-71.169914},
{lat:42.569648, lng:-71.169704},
{lat:42.569648, lng:-71.169704},
{lat:42.569891, lng:-71.169657},
{lat:42.569754, lng:-71.16867},
{lat:42.569754, lng:-71.16867},
{lat:42.569591, lng:-71.167577},
{lat:42.568797, lng:-71.1649},
{lat:42.568968, lng:-71.164894},
{lat:42.568968, lng:-71.164894},
{lat:42.570525, lng:-71.164838},
{lat:42.570522, lng:-71.164616},
{lat:42.570522, lng:-71.164616},
{lat:42.570456, lng:-71.161781},
{lat:42.570415, lng:-71.161516},
{lat:42.570387, lng:-71.161436},
{lat:42.570287, lng:-71.161252},
{lat:42.570225, lng:-71.16117},
{lat:42.570129, lng:-71.161093},
{lat:42.56997, lng:-71.161058},
{lat:42.569705, lng:-71.16111},
{lat:42.569198, lng:-71.16125},
{lat:42.569198, lng:-71.16125},
{lat:42.568912, lng:-71.161313},
{lat:42.568834, lng:-71.161305},
{lat:42.568849, lng:-71.161249},
{lat:42.568847, lng:-71.161183},
{lat:42.568774, lng:-71.160766},
{lat:42.568774, lng:-71.160766},
{lat:42.568589, lng:-71.159719},
{lat:42.568428, lng:-71.159768},
{lat:42.568424, lng:-71.159744},
{lat:42.568424, lng:-71.159744},
{lat:42.568428, lng:-71.159768},
{lat:42.56801, lng:-71.159869},
{lat:42.567726, lng:-71.159877},
{lat:42.567492, lng:-71.159859},
{lat:42.564748, lng:-71.159487},
{lat:42.562506, lng:-71.159255},
{lat:42.562332, lng:-71.159257},
{lat:42.562201, lng:-71.159287},
{lat:42.562177, lng:-71.159173},
{lat:42.562177, lng:-71.159173},
{lat:42.562052, lng:-71.158575},
{lat:42.561944, lng:-71.158222},
{lat:42.561843, lng:-71.158004},
{lat:42.561767, lng:-71.157881},
{lat:42.561034, lng:-71.156934},
{lat:42.560005, lng:-71.155817},
{lat:42.559826, lng:-71.15566},
{lat:42.559053, lng:-71.155066},
{lat:42.558728, lng:-71.154789},
{lat:42.558403, lng:-71.154491},
{lat:42.558132, lng:-71.154212},
{lat:42.557879, lng:-71.153889},
{lat:42.557567, lng:-71.153401},
{lat:42.557426, lng:-71.153157},
{lat:42.557246, lng:-71.152687},
{lat:42.557149, lng:-71.152509},
{lat:42.555714, lng:-71.15093},
{lat:42.555152, lng:-71.150339},
{lat:42.554879, lng:-71.150082},
{lat:42.554728, lng:-71.14998},
{lat:42.554617, lng:-71.149931},
{lat:42.554285, lng:-71.149843},
{lat:42.554013, lng:-71.149828},
{lat:42.553528, lng:-71.149898},
{lat:42.552674, lng:-71.150072},
{lat:42.552444, lng:-71.150071},
{lat:42.552057, lng:-71.149964},
{lat:42.551871, lng:-71.149851},
{lat:42.551679, lng:-71.149674},
{lat:42.55125, lng:-71.148959},
{lat:42.551172, lng:-71.14885},
{lat:42.551036, lng:-71.148717},
{lat:42.549658, lng:-71.147809},
{lat:42.549658, lng:-71.147809},
{lat:42.549552, lng:-71.147739},
{lat:42.549772, lng:-71.147163},
{lat:42.54989, lng:-71.146896},
{lat:42.550463, lng:-71.145849},
{lat:42.550666, lng:-71.145581},
{lat:42.550898, lng:-71.145393},
{lat:42.551893, lng:-71.144717},
{lat:42.552228, lng:-71.144508},
{lat:42.552801, lng:-71.144281},
{lat:42.553931, lng:-71.143695},
{lat:42.554246, lng:-71.143575},
{lat:42.554447, lng:-71.143556},
{lat:42.554637, lng:-71.143585},
{lat:42.555187, lng:-71.14378},
{lat:42.556067, lng:-71.144115},
{lat:42.556574, lng:-71.144351},
{lat:42.55686, lng:-71.144452},
{lat:42.557212, lng:-71.144505},
{lat:42.558781, lng:-71.14466},
{lat:42.559484, lng:-71.144704},
{lat:42.55996, lng:-71.144773},
{lat:42.560654, lng:-71.144686},
{lat:42.560932, lng:-71.144695},
{lat:42.561321, lng:-71.144753},
{lat:42.561568, lng:-71.144832},
{lat:42.561781, lng:-71.144962},
{lat:42.562101, lng:-71.145215},
{lat:42.562282, lng:-71.145311},
{lat:42.562482, lng:-71.145366},
{lat:42.563452, lng:-71.145426},
{lat:42.563708, lng:-71.14548},
{lat:42.563978, lng:-71.145606},
{lat:42.564405, lng:-71.145956},
{lat:42.564953, lng:-71.146473},
{lat:42.565304, lng:-71.146788},
{lat:42.56544, lng:-71.14689},
{lat:42.565709, lng:-71.147024},
{lat:42.566114, lng:-71.147185},
{lat:42.566716, lng:-71.147593},
{lat:42.567232, lng:-71.148012},
{lat:42.567386, lng:-71.148113},
{lat:42.567544, lng:-71.148183},
{lat:42.56775, lng:-71.148236},
{lat:42.567967, lng:-71.14825},
{lat:42.568255, lng:-71.148184},
{lat:42.568449, lng:-71.148091},
{lat:42.56863, lng:-71.147954},
{lat:42.568852, lng:-71.147716},
{lat:42.568995, lng:-71.147488},
{lat:42.569445, lng:-71.146533},
{lat:42.569593, lng:-71.146185},
{lat:42.569593, lng:-71.146185},
{lat:42.56966, lng:-71.146028},
{lat:42.569796, lng:-71.145572},
{lat:42.569875, lng:-71.145198},
{lat:42.569967, lng:-71.144559},
{lat:42.57, lng:-71.144161},
{lat:42.570017, lng:-71.143761},
{lat:42.570008, lng:-71.143341},
{lat:42.569916, lng:-71.142357},
{lat:42.569926, lng:-71.142133},
{lat:42.569988, lng:-71.141828},
{lat:42.569812, lng:-71.141745},
{lat:42.569812, lng:-71.141745},
{lat:42.569244, lng:-71.141538},
{lat:42.569128, lng:-71.142311},
{lat:42.569128, lng:-71.142311},
{lat:42.568878, lng:-71.143966},
{lat:42.568847, lng:-71.144091},
{lat:42.568817, lng:-71.144144},
{lat:42.568769, lng:-71.144168},
{lat:42.568678, lng:-71.144176},
{lat:42.568549, lng:-71.144149},
{lat:42.568549, lng:-71.144149},
{lat:42.568257, lng:-71.144069},
{lat:42.568161, lng:-71.143994},
{lat:42.568123, lng:-71.143916},
{lat:42.568115, lng:-71.143763},
{lat:42.568353, lng:-71.142039},
{lat:42.568353, lng:-71.142039},
{lat:42.568458, lng:-71.141278},
{lat:42.568527, lng:-71.141123},
{lat:42.567938, lng:-71.140653},
{lat:42.567938, lng:-71.140653},
{lat:42.567636, lng:-71.140412},
{lat:42.56761, lng:-71.140596},
{lat:42.567315, lng:-71.141316},
{lat:42.567447, lng:-71.141421},
{lat:42.567447, lng:-71.141421},
{lat:42.567603, lng:-71.141544},
{lat:42.567667, lng:-71.141654},
{lat:42.567686, lng:-71.141776},
{lat:42.567309, lng:-71.143828},
{lat:42.567309, lng:-71.143828},
{lat:42.567245, lng:-71.144172},
{lat:42.567199, lng:-71.144287},
{lat:42.567148, lng:-71.144342},
{lat:42.567086, lng:-71.14437},
{lat:42.567021, lng:-71.14437},
{lat:42.56662, lng:-71.144276},
{lat:42.566572, lng:-71.144238},
{lat:42.566539, lng:-71.144179},
{lat:42.566523, lng:-71.144107},
{lat:42.566609, lng:-71.143626},
{lat:42.566609, lng:-71.143626},
{lat:42.567041, lng:-71.141447},
{lat:42.567072, lng:-71.141368},
{lat:42.567125, lng:-71.141308},
{lat:42.567194, lng:-71.141281},
{lat:42.567236, lng:-71.141288},
{lat:42.567236, lng:-71.141288},
{lat:42.567315, lng:-71.141316},
{lat:42.56761, lng:-71.140596},
{lat:42.567636, lng:-71.140412},
{lat:42.568129, lng:-71.140806},
{lat:42.568129, lng:-71.140806},
{lat:42.568527, lng:-71.141123},
{lat:42.568827, lng:-71.141324},
{lat:42.569244, lng:-71.141538},
{lat:42.569883, lng:-71.141771},
{lat:42.569988, lng:-71.141828},
{lat:42.570103, lng:-71.141539},
{lat:42.570781, lng:-71.14042},
{lat:42.571003, lng:-71.139934},
{lat:42.571114, lng:-71.139539},
{lat:42.571517, lng:-71.137842},
{lat:42.571639, lng:-71.13712},
{lat:42.571744, lng:-71.137148},
{lat:42.571744, lng:-71.137148},
{lat:42.572129, lng:-71.137251},
{lat:42.572804, lng:-71.137508},
{lat:42.573753, lng:-71.137927},
{lat:42.573838, lng:-71.137918},
{lat:42.57392, lng:-71.137827},
{lat:42.574207, lng:-71.136572},
{lat:42.574249, lng:-71.136247},
{lat:42.574248, lng:-71.13589},
{lat:42.574159, lng:-71.135065},
{lat:42.574159, lng:-71.135065},
{lat:42.574129, lng:-71.134786},
{lat:42.57483, lng:-71.13464},
{lat:42.57483, lng:-71.13464},
{lat:42.576314, lng:-71.134331},
{lat:42.57637, lng:-71.134291},
{lat:42.576411, lng:-71.134224},
{lat:42.576428, lng:-71.134084},
{lat:42.576325, lng:-71.133209},
{lat:42.576325, lng:-71.133209},
{lat:42.576292, lng:-71.132929},
{lat:42.576249, lng:-71.132831},
{lat:42.576176, lng:-71.132776},
{lat:42.576113, lng:-71.132772},
{lat:42.574679, lng:-71.133057},
{lat:42.574679, lng:-71.133057},
{lat:42.572793, lng:-71.133462},
{lat:42.572846, lng:-71.133919},
{lat:42.572846, lng:-71.133919},
{lat:42.573047, lng:-71.135642},
{lat:42.573071, lng:-71.136173},
{lat:42.573031, lng:-71.13655},
{lat:42.572855, lng:-71.137293},
{lat:42.572855, lng:-71.137293},
{lat:42.572804, lng:-71.137508},
{lat:42.572129, lng:-71.137251},
{lat:42.571639, lng:-71.13712},
{lat:42.571635, lng:-71.137143},
{lat:42.571635, lng:-71.137143},
{lat:42.571517, lng:-71.137842},
{lat:42.571114, lng:-71.139539},
{lat:42.571003, lng:-71.139934},
{lat:42.570781, lng:-71.14042},
{lat:42.570103, lng:-71.141539},
{lat:42.569988, lng:-71.141828},
{lat:42.569926, lng:-71.142133},
{lat:42.569916, lng:-71.142357},
{lat:42.570008, lng:-71.143341},
{lat:42.570017, lng:-71.143761},
{lat:42.57, lng:-71.144161},
{lat:42.569967, lng:-71.144559},
{lat:42.569875, lng:-71.145198},
{lat:42.569837, lng:-71.145382},
{lat:42.569837, lng:-71.145382},
{lat:42.569796, lng:-71.145572},
{lat:42.56966, lng:-71.146028},
{lat:42.569445, lng:-71.146533},
{lat:42.568995, lng:-71.147488},
{lat:42.568852, lng:-71.147716},
{lat:42.56863, lng:-71.147954},
{lat:42.568449, lng:-71.148091},
{lat:42.568545, lng:-71.148266},
{lat:42.568637, lng:-71.14834},
{lat:42.568792, lng:-71.148398}]

export default route